import {combineReducers} from 'redux';
import userSettingsReducer from './userSettingsReducer';
import settingsOptionsReducer from './settingsOptionsReducer';
import productReducer from './productReducer';
import currencyRatesReducer from './currencyRatesReducer';
import productRecommendationsReducer from './productRecommendationsReducer';
import filterSettingsReducer from './filterSettingsReducer';
import filterConfigReducer from './filterConfigReducer';
import galleryReducer from './galleryReducer';
import productFavouritesReducer from './productFavouritesReducer';
import plpSeoTextReducer from './plpSeoTextReducer';
import trackingReducer from './trackingReducer';
import staffOnlyReducer from './staffOnlyReducer';
import recentlyViewedProductsReducer from './recentlyViewedProductsReducer';
import bannerReducer from './bannerReducer';
import editorsPicksReducer from './editorsPicksReducer';
import artistsDiscoverReducer from './artistsDiscoverReducer';
import artistsPopularReducer from './artistsPopularReducer';

const rootReducer = combineReducers({
  userSettings: userSettingsReducer,
  settingsOptions: settingsOptionsReducer,
  filterSettings: filterSettingsReducer,
  filterConfig: filterConfigReducer,
  gallery: galleryReducer,
  product: productReducer,
  currencyRates: currencyRatesReducer,
  productRecommendations: productRecommendationsReducer,
  productFavourites: productFavouritesReducer,
  plpSeoText: plpSeoTextReducer,
  tracking: trackingReducer,
  staffOnly: staffOnlyReducer,
  recentlyViewedProducts: recentlyViewedProductsReducer,
  banner: bannerReducer,
  editorsPicks: editorsPicksReducer,
  artistsDiscover: artistsDiscoverReducer,
  artistsPopular: artistsPopularReducer,
});

export default rootReducer;
