const initialState = {
  0: {
    type: 'price',
    collapsible: true,
    default_collapsed: false,
  },
  1: {
    type: 'size',
    collapsible: true,
    default_collapsed: false,
  },
  2: {
    type: 'category',
    collapsible: true,
    default_collapsed: false,
  },
  3: {
    type: 'subject',
    collapsible: true,
    default_collapsed: false,
  },
  4: {
    type: 'shipTo',
    collapsible: true,
    default_collapsed: false,
  },
  5: {
    type: 'shipFrom',
    collapsible: true,
    default_collapsed: false,
  },
  6: {
    type: 'prints',
    collapsible: true,
    default_collapsed: false,
  },
  7: {
    type: 'framed',
    collapsible: true,
    default_collapsed: false,
  },
  8: {
    type: 'style',
    collapsible: true,
    default_collapsed: false,
  },
  9: {
    type: 'colour',
    collapsible: true,
    default_collapsed: false,
  },
  10: {
    type: 'orientation',
    collapsible: true,
    default_collapsed: false,
  },
};

const filterConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return {...state};
  }
};

export default filterConfigReducer;
export const selectFilterConfig = (state) => state.filterConfig;
