import type {SuccessApiResponseBody} from '@/redux/actions/artistsListActions';
import type {ArtistCardProps} from '@/molecules/Cards/Artist/typings';
import type {RootState} from '../store';
import type {StatusType, ReduxAction} from './typings';

import {
  STATUS_IDLE,
  STATUS_LOADING,
  STATUS_SUCCEEDED,
  STATUS_FAILED,
  LOAD_ARTISTS_DISCOVER_LIST_SUCCESS,
  LOAD_ARTISTS_DISCOVER_LIST_REQUEST,
  LOAD_ARTISTS_DISCOVER_LIST_FAILURE,
} from '../actionTypes';

type ArtistsDiscoverActionTypes =
  | typeof LOAD_ARTISTS_DISCOVER_LIST_REQUEST
  | typeof LOAD_ARTISTS_DISCOVER_LIST_SUCCESS
  | typeof LOAD_ARTISTS_DISCOVER_LIST_FAILURE;

export interface ArtistsDiscoverState {
  status: StatusType;
  data: ArtistCardProps[];
  error: null | string;
}

export const initialState: ArtistsDiscoverState = {
  status: STATUS_IDLE,
  data: [],
  error: null,
};

const artistsDiscoverReducer = (
  state = initialState,
  action: ReduxAction<SuccessApiResponseBody, ArtistsDiscoverActionTypes>,
) => {
  switch (action.type) {
    case LOAD_ARTISTS_DISCOVER_LIST_REQUEST:
      return {
        ...state,
        status: STATUS_LOADING,
        error: null,
      };

    case LOAD_ARTISTS_DISCOVER_LIST_SUCCESS:
      return {
        ...state,
        status: STATUS_SUCCEEDED,
        data: action.response?.data.results as ArtistCardProps[],
      };

    case LOAD_ARTISTS_DISCOVER_LIST_FAILURE:
      return {
        ...state,
        status: STATUS_FAILED,
        error: action.error?.message as string,
      };

    default:
      return state;
  }
};

export const selectArtistsDiscover = (state: RootState) => state.artistsDiscover;

export default artistsDiscoverReducer;
