import type {StatusType} from './typings';

import {
  LOAD_BANNER_FAILURE,
  LOAD_BANNER_REQUEST,
  LOAD_BANNER_SUCCESS,
  STATUS_FAILED,
  STATUS_IDLE,
  STATUS_LOADING,
  STATUS_SUCCEEDED,
} from '../actionTypes';
import {BannerProps} from '@/molecules/Banners/HomePage/typings';

const initialState: BannerReducerProps = {
  status: STATUS_IDLE,
  data: null,
  error: null,
};

const bannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_BANNER_REQUEST:
      return {...state, status: STATUS_LOADING};
    case LOAD_BANNER_SUCCESS:
      return {status: STATUS_SUCCEEDED, data: {...action.response.data}, error: null};
    case LOAD_BANNER_FAILURE:
      return {...state, status: STATUS_FAILED, error: action.error};
    default:
      return {...state};
  }
};

export default bannerReducer;

export const selectBannerState = (state): BannerReducerProps => state.banner;

interface BannerReducerProps {
  status: StatusType;
  data: BannerProps | null;
  error: string | null;
}
