const size = {
  xl: '1200px',
  l: '992px',
  m: '768px',
  s: '576px',
} as const;

const device = {
  xl: `(min-width: ${size.xl})`,
  l: `(min-width: ${size.l})`,
  m: `(min-width: ${size.m})`,
  s: `(min-width: ${size.s})`,
} as const;

export default {size, device};
