import {
  STATUS_IDLE,
  STATUS_LOADING,
  STATUS_SUCCEEDED,
  STATUS_FAILED,
  LOAD_PRODUCT_RECS_REQUEST,
  LOAD_PRODUCT_RECS_SUCCESS,
  LOAD_PRODUCT_RECS_FAILURE
} from '../actionTypes';

const initialState = {
  status: STATUS_IDLE,
  id: null, // id of the recommendation so we can track it
  data: {},
  error: null
};

const productRecommendationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PRODUCT_RECS_REQUEST:
      return {...state, status: STATUS_LOADING};
    case LOAD_PRODUCT_RECS_SUCCESS:
      if (action.response?.data?.recommendations) {
        const recs = {...state.data};
        action.response.data.recommendations.forEach((item) => {
          recs[item['product']['_id']['original_id']] = item['product'];
        })
        return {...state, status: STATUS_SUCCEEDED, id: action.response.data.id, data: recs};
      } else {
        return {...state, status: STATUS_FAILED, error: 'Invalid data format'};
      }
    case LOAD_PRODUCT_RECS_FAILURE:
      return {...state, status: STATUS_FAILED, error: action.error};
    default:
      return {...state};
  }
};

export default productRecommendationsReducer;

// Export a reusable selector so state structure is abstracted for components
export const selectProductRecommendationsData = state => state.productRecommendations.data;
export const selectProductRecommendationsId = state => state.productRecommendations.id;
