const events = {};

const on = (id, callback) => {
  /* start-dev-block */
  if (!callback || typeof callback !== 'function') {
    console.warn(
        'A function must be provided as the second argument to events.on()',
    );
  }
  /* end-dev-block */

  if (events[id] === undefined) {
    events[id] = [];
  }
  events[id].push(callback);
};

const off = (id, callback) => {
  if (events[id]) {
    for (let i = 0; i < events[id].length; i++) {
      if (events[id][i] === callback) {
        events[id].splice(i, 1);
        break;
      }
    }
  }
};

const emit = (id, payload) => {
  if (events[id] && events[id].length) {
    events[id].forEach((callback) => callback(payload));
  }
};

const emitFactory = (id, payload) => (_payload) =>
  emit(id, typeof payload !== 'undefined' ? payload : _payload);

export {on, off, emit, emitFactory};
