/* eslint-disable camelcase */
import {
  STATUS_IDLE,
  STATUS_LOADING,
  STATUS_SUCCEEDED,
  STATUS_FAILED,
  SET_CUSTOMER_INFO,
  SET_FAVOURITE_COUNT,
  FAVOURITE_INCREMENT,
  FAVOURITE_DECREMENT,
  ADD_TO_COLLECTION,
  REMOVE_FROM_COLLECTION,
  SET_ARTIST_ID,
  SET_FAVOURITES_ARRAY,
  LOAD_SHIPPING_DATA_REQUEST,
  LOAD_SHIPPING_DATA_SUCCESS,
  LOAD_SHIPPING_DATA_FAILURE,
  CLEAR_SHIPPING_DATA,
  LOAD_AUTO_DETECTED_TAGS_SUCCESS,
  SET_FAVOURITE_SUCCESS,
  LOAD_PRODUCT_PRINTS_SUCCESS,
  SET_PRODUCT_PRINTS,
} from '../actionTypes';
import {CurrencyType, CurrencySymbolType} from './userSettingsReducer';

const initialState: ProductState = {
  status: STATUS_IDLE,
  data: {
    id: null,
    freeShipping: false,
    favourited: false,
    favouriteCount: null,
    collections: [],
    artistId: null,
    customerFavourites: [],
    offerPricing: null,
    prints: [],
    shipping: {
      original: <ShippingDataProps>{},
      prints: {},
    },
    autoTags: [],
  },
  error: null,
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ARTIST_ID:
      return {...state, data: {...state.data, artistId: action.payload}};
    case SET_FAVOURITES_ARRAY:
      return {...state, data: {...state.data, customerFavourites: action.payload}};
    case SET_CUSTOMER_INFO:
      return {...state, data: {...state.data, ...action.payload}};
    case SET_FAVOURITE_COUNT:
      return {...state, data: {...state.data, favouriteCount: action.payload.favouriteCount}};
    case FAVOURITE_INCREMENT:
      return {
        ...state,
        data: {...state.data, favouriteCount: Number(state.data.favouriteCount) + 1},
      };
    case FAVOURITE_DECREMENT:
      return {
        ...state,
        data: {...state.data, favouriteCount: Number(state.data.favouriteCount) - 1},
      };
    case SET_FAVOURITE_SUCCESS:
      return {...state, data: {...state.data, favourited: !state.data.favourited}};
    case ADD_TO_COLLECTION:
      if (state.data.collections.includes(action.payload.addNumber)) {
        return {...state};
      } else {
        return {
          ...state,
          data: {
            ...state.data,
            collections: [...state.data.collections, action.payload.addNumber],
          },
        };
      }
    case REMOVE_FROM_COLLECTION:
      return {
        ...state,
        data: {
          ...state.data,
          collections: state.data.collections.filter(
            (collection) => collection !== action.payload.removeNumber,
          ),
        },
      };
    case LOAD_SHIPPING_DATA_REQUEST:
      return {
        ...state,
        status: STATUS_LOADING,
      };
    case LOAD_SHIPPING_DATA_SUCCESS:
      const shippingData = {
        original: {...state.data.shipping.original},
        prints: {...state.data.shipping.prints},
      };
      if (action.productType === 'original') {
        shippingData[action.productType] = action.response.data;
      } else {
        shippingData[action.productType][action.slug] = action.response.data;
      }
      return {
        status: STATUS_SUCCEEDED,
        data: {
          ...state.data,
          shipping: shippingData,
          error: null,
        },
        error: null,
      };
    case LOAD_SHIPPING_DATA_FAILURE:
      return {
        ...state,
        status: STATUS_FAILED,
        error: action.error,
      };
    case CLEAR_SHIPPING_DATA:
      return {
        status: state.status,
        data: {
          ...state.data,
          shipping: {
            ...initialState.data.shipping,
          },
        },
        error: state.error,
      };
    case LOAD_AUTO_DETECTED_TAGS_SUCCESS:
      return {
        status: state.status,
        data: {...state.data, autoTags: [...action.response.data]},
        error: state.error,
      };
    case LOAD_PRODUCT_PRINTS_SUCCESS:
      return {...state, data: {...state.data, prints: [...action.response.data]}};
    case SET_PRODUCT_PRINTS:
      return {...state, data: {...state.data, prints: [...action.payload]}};
    default:
      return {...state};
  }
};

export default productReducer;

export const selectProductData = (state): ProductData => state.product.data;

interface AutoDetectedTag {
  name: string;
  confidence: number;
}

interface ShippingDataProps {
  shipping_to: string;
  shipping_from: string;
  dispatch_days: number;
  package_type: string;
  user_entry?: {
    price_display: string;
    is_free: boolean;
  };
}

interface OfferPricing {
  GBP: {amount: number};
  USD: {amount: number};
  AUD: {amount: number};
  EUR: {amount: number};
  CAD: {amount: number};
}

interface PrintsPricing {
  original_amount: number;
  original_price: `${CurrencySymbolType}${number}`;
  current_amount: number;
  current_price: `${CurrencySymbolType}${number}`;
  currency_symbol: CurrencySymbolType;
}

export interface PrintsData {
  id: number;
  slug: string;
  quantity: number;
  edition: number;
  substrate: string;
  dimensions_text: string;
  dimensions_text_short: string;
  dimensions_text_cm: string;
  dimensions_text_short_cm: string;
  dimensions_text_in: string;
  dimensions_text_short_in: string;
  is_in_stock: boolean;
  currency: CurrencyType;
  pricing: {
    GBP: PrintsPricing;
    USD: PrintsPricing;
    EUR: PrintsPricing;
    CAD: PrintsPricing;
    AUD: PrintsPricing;
  };
  original_currency: CurrencyType;
  artist_country: string;
  price: `${CurrencySymbolType}${number}`;
  detailed_price: `${CurrencySymbolType}${number}`;
  has_original_price: boolean;
  original_price: string;
  detailed_original_price: string;
  is_free_shipping: boolean;
  shipping_cost: `${CurrencySymbolType}${number}`;
  detailed_shipping_cost: `${CurrencySymbolType}${number}`;
}

interface ProductData {
  id: number | null;
  freeShipping: boolean;
  favourited: boolean;
  favouriteCount: number | null;
  collections: number[];
  artistId: number | null;
  customerFavourites: number[];
  offerPricing: null | OfferPricing;
  prints: PrintsData[];
  shipping: {
    original: ShippingDataProps;
    prints: {
      [key: string]: ShippingDataProps;
    };
  };
  autoTags: AutoDetectedTag[];
}

type StatusType =
  | typeof STATUS_IDLE
  | typeof STATUS_LOADING
  | typeof STATUS_SUCCEEDED
  | typeof STATUS_FAILED;

interface ProductState {
  status: StatusType;
  data: ProductData;
  error: string | null;
}
