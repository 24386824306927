import 'intersection-observer';
import '../src/app.scss';
import '../src/i18n';

import React, {useEffect, type ReactNode} from 'react';
import {Provider, useDispatch} from 'react-redux';

import type {AppProps} from 'next/app';

import ModalsGroup from '../src/components/modals-group/modals-group';
import toastFactory from '../vendors/bootstrap/components/toast';

import {loadSettingsOptions, loadUserSettings} from '@/redux/actions/settingsActions';
import {useStore} from '@/redux/store';
import {customEvent as gtmEvent, data as gtmData, getInitialDataLayer} from '@/utils/gtm';
import elementFactory from '@/utils/element';
import {bugsnagStart} from '@/services/bugsnag';
import {GlobalStyle} from './styles';

bugsnagStart();

const dispatchInitialDataLayerEvents = () => {
  try {
    const initData = getInitialDataLayer();
    gtmData(initData);
    gtmEvent(`environment_${process.env.ENVIRONMENT}`);
    gtmEvent(`gtm_datalayer_initialized`);
    if ('userId' in initData && initData['userId']) {
      gtmEvent(`user_logged_in`);
    }
  } catch (e) {
    console.log(e);
  }
};

const AppComponents = ({children}: {children: ReactNode}): JSX.Element => {
  // This components wrapper allows the use of hooks within the core app system
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadSettingsOptions());
    dispatch(loadUserSettings('_app'));
  }, []);

  return <>{children}</>;
};

interface AppPageProps extends AppProps {
  err?:
    | (Error & {
        statusCode?: number;
      })
    | null;
}
/**
 * @description The `_app.tsx` component in Next.js serves as the root App component
 * that initializes pages and manages global configurations,
 * allowing for consistent layout, and context across the entire application.
 * @param {NextComponentType} Component The component to render.
 * @param {Record<string, unknown>} pageProps The props for the app components.
 * @param {Error|undefined|null} err Any errors from or to the app.
 * @return {JSX.Element} The app component to initialize pages.
 */
const MyApp = ({Component, pageProps, err}: AppPageProps) => {
  const store = useStore(pageProps.initialReduxState);

  useEffect(() => {
    dispatchInitialDataLayerEvents();
  }, []);

  useEffect(() => {
    try {
      const $ = elementFactory(document, window);
      window.$ = $;
      window.toast = toastFactory(document, window, $);
    } catch (e) {
      console.log(e);
    }
  }, []);

  // Workaround for https://github.com/vercel/next.js/issues/8592
  return (
    <Provider store={store}>
      <AppComponents>
        <GlobalStyle />
        <ModalsGroup />
        <Component {...pageProps} err={err} />
      </AppComponents>
    </Provider>
  );
};

export default MyApp;
