// eslint-disable-next-line require-jsdoc
function callAPIMiddleware({dispatch, getState}) {
  return (next) => (action) => {
    const {
      types,
      callAPI,
      callCache = null,
      shouldCallAPI = () => true,
      payload = {},
      onSuccess = [],
      onError = [],
      onCleanup = [],
    } = action;

    if (!types) {
      // Normal action: pass it on
      return next(action);
    }

    if (
      !Array.isArray(types) ||
      types.length !== 3 ||
      !types.every((type) => typeof type === 'string')
    ) {
      throw new Error('Expected an array of three string types.');
    }

    if (typeof callAPI !== 'function') {
      throw new Error('Expected callAPI to be a function.');
    }

    if (!shouldCallAPI(getState())) {
      return;
    }

    const [requestType, successType, failureType] = types;

    dispatch(
      Object.assign({}, payload, {
        type: requestType,
      }),
    );

    if (callCache !== null) {
      const results = callCache(getState());
      if (results) {
        try {
          dispatch(
            Object.assign({}, payload, {
              response: results,
              type: successType,
            }),
          );

          onSuccess.forEach(function (func) {
            func(results.data);
          });
        } catch (e) {
          // ignore error
        }

        onCleanup.forEach(function (func) {
          func();
        });

        return;
      }
    }

    return callAPI()
      .then(
        (response) => {
          dispatch(
            Object.assign({}, payload, {
              response,
              type: successType,
            }),
          );

          onSuccess.forEach(function (func) {
            func(response.data);
          });
        },
        (error) => {
          dispatch(
            Object.assign({}, payload, {
              error,
              type: failureType,
            }),
          );

          onError.forEach(function (func) {
            func(error);
          });
        },
      )
      .finally(() => {
        onCleanup.forEach(function (func) {
          func();
        });
      });
  };
}

export default callAPIMiddleware;
