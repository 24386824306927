'use client';

import React, {useState, useEffect, useRef} from 'react';
import dynamic from 'next/dynamic';

import constantsFactory from '@/utils/constants';
import {on, off} from '@/utils/events';
const NotificationSlider = dynamic(() => import('../notification-slider/notification-slider'), {
  ssr: false,
});
const AlertWelcome = dynamic(() => import('../alert-welcome/alert-welcome'), {
  ssr: false,
});
const ModalConfirmation = dynamic(() => import('../modal-confirmation/modal-confirmation'), {
  ssr: false,
});
const ModalJoinThanks = dynamic(() => import('../modal-join-thanks/modal-join-thanks'), {
  ssr: false,
});
const ModalNewsletter = dynamic(() => import('../modal-newsletter/modal-newsletter'), {
  ssr: false,
});
const ModalYourself = dynamic(() => import('../modal-yourself/modal-yourself'), {
  ssr: false,
});
const ModalWelcome = dynamic(() => import('../modal-welcome/modal-welcome'), {
  ssr: false,
});
const ModalLogin = dynamic(() => import('../modal-login/modal-login'), {
  ssr: false,
});
const ModalJoin = dynamic(() => import('../modal-join/modal-join'), {
  ssr: false,
});
import {StyledBootstrapScopeWrapper} from './styles';

const {EVENTS} = constantsFactory();
const initialStatus = {
  AlertWelcome: false,
  ModalLogin: false,
  ModalJoin: false,
  ModalNewsletter: false,
  ModalWelcome: false,
  ModalYourself: false,
  ModalJoinThanks: false,
  NotificationSlider: false,
  ModalConfirmation: false,
};

/**
 * @description Check if should components and resources to be loaded;
 * This helps to reduce initial bundle size, and at same time
 * this is necessary to avoid possible layout shifts
 * when component is imported on conditional render first time.
 * @param {Record<string, boolean>} status The status of the modals.
 * @return {boolean} The flag to determine if should load resources.
 */
const useShouldLoadResource = (status: typeof initialStatus) => {
  const [shouldLoadOnce, setShouldLoadOnce] = useState(false);
  const loadedOnceRef = useRef(false);
  const isAnyModalOpen = Object.values(status).some((value) => value);

  useEffect(() => {
    if (loadedOnceRef.current || !isAnyModalOpen) return;
    loadedOnceRef.current = true;
    setShouldLoadOnce(isAnyModalOpen);
  }, [isAnyModalOpen]);

  return shouldLoadOnce;
};

const ModalsGroup = (): JSX.Element => {
  const [notificationContent, setNotificationContent] = useState<string | null>(null);
  const [confirmationMessage, setConfirmationMessage] = useState<string | null>(null);
  const [user, setUser] = useState<Record<string, unknown> | null>(null);
  const [status, setStatus] = useState(initialStatus);
  const shouldLoadOnce = useShouldLoadResource(status);

  const createHandler = (modalName: keyof typeof initialStatus, type: 'open' | 'close') => () => {
    setStatus((current) => ({...current, [modalName]: type === 'open'}));
  };

  /**
   * @description ModalLogin events subscription
   */
  useEffect(() => {
    const handleOpen = createHandler('ModalLogin', 'open');
    const handleClose = createHandler('ModalLogin', 'close');
    on(EVENTS.CLICK_ON_LOGIN, handleOpen);
    on(EVENTS.LOGIN_SUCCESS, handleClose);
    on(EVENTS.CLICK_ON_JOIN, handleClose);
    return () => {
      off(EVENTS.CLICK_ON_LOGIN, handleOpen);
      off(EVENTS.LOGIN_SUCCESS, handleClose);
      off(EVENTS.CLICK_ON_JOIN, handleClose);
    };
  }, []);

  /**
   * @description ModalJoin events subscription
   */
  useEffect(() => {
    const handleOpen = createHandler('ModalJoin', 'open');
    const handleClose = createHandler('ModalJoin', 'close');
    on(EVENTS.CLICK_ON_JOIN, handleOpen);
    on(EVENTS.REGISTRATION_SUCCESSFUL, handleClose);
    return () => {
      off(EVENTS.CLICK_ON_JOIN, handleOpen);
      off(EVENTS.REGISTRATION_SUCCESSFUL, handleClose);
    };
  }, []);

  /**
   * @description ModalNewsletter events subscription
   */
  useEffect(() => {
    const handleOpen = createHandler('ModalNewsletter', 'open');
    const handleClose = createHandler('ModalNewsletter', 'close');
    on(EVENTS.REGISTRATION_SUCCESSFUL, handleOpen);
    on(EVENTS.NEWSLETTER_DISMISS, handleClose);
    return () => {
      off(EVENTS.REGISTRATION_SUCCESSFUL, handleOpen);
      off(EVENTS.NEWSLETTER_DISMISS, handleClose);
    };
  }, []);

  /**
   * @description ModalWelcome events subscription
   */
  useEffect(() => {
    const handleOpen = createHandler('ModalWelcome', 'open');
    on(EVENTS.NEWSLETTER_DISMISS, handleOpen);
    return () => {
      off(EVENTS.NEWSLETTER_DISMISS, handleOpen);
    };
  }, []);

  /**
   * @description ModalYourself events subscription
   */
  useEffect(() => {
    const handleOpen = createHandler('ModalYourself', 'open');
    const handleClose = createHandler('ModalYourself', 'close');
    on(EVENTS.CLICK_TELL_ABOUT_YOURSELF, handleOpen);
    on(EVENTS.USER_UPDATED, handleClose);
    return () => {
      off(EVENTS.CLICK_TELL_ABOUT_YOURSELF, handleOpen);
      off(EVENTS.USER_UPDATED, handleClose);
    };
  }, []);

  /**
   * @description ModalJoinThanks events subscription
   */
  useEffect(() => {
    const handleOpen = createHandler('ModalJoinThanks', 'open');
    const onUserUpdated = (user: Record<string, unknown>) => {
      setUser(user);
      handleOpen();
    };
    on(EVENTS.USER_UPDATED, onUserUpdated);
    return () => {
      off(EVENTS.USER_UPDATED, onUserUpdated);
    };
  }, []);

  /**
   * @description ModalConfirmation events subscription
   */
  useEffect(() => {
    const handleOpen = createHandler('ModalConfirmation', 'open');
    const onMessage = (message: string) => {
      handleOpen();
      setConfirmationMessage(message);
    };
    on(EVENTS.OPEN_CONFIRMATION_MSG, onMessage);
    return () => {
      off(EVENTS.OPEN_CONFIRMATION_MSG, onMessage);
    };
  }, []);

  /**
   * @description AlertWelcome events subscription
   */
  useEffect(() => {
    const handleOpen = createHandler('AlertWelcome', 'open');
    on(EVENTS.REGISTRATION_SUCCESSFUL, handleOpen);
    return () => {
      off(EVENTS.REGISTRATION_SUCCESSFUL, handleOpen);
    };
  }, []);

  /**
   * @description NotificationSlider events subscription
   */
  useEffect(() => {
    const handleOpen = createHandler('NotificationSlider', 'open');
    const handleClose = createHandler('NotificationSlider', 'close');
    const onNotificationOpen = (content: string) => {
      setNotificationContent(content);
      handleOpen();
    };
    const onNotificationClose = (content: null | string) => {
      setNotificationContent(content);
      setTimeout(handleClose, 3000);
    };

    on(EVENTS.OPEN_NOTIFICATION_SLIDER, onNotificationOpen);
    on(EVENTS.CLOSE_NOTIFICATION_SLIDER, onNotificationClose);
    return () => {
      off(EVENTS.OPEN_NOTIFICATION_SLIDER, onNotificationOpen);
      off(EVENTS.CLOSE_NOTIFICATION_SLIDER, onNotificationClose);
    };
  });

  return (
    <StyledBootstrapScopeWrapper shouldLoadStyles={shouldLoadOnce}>
      {shouldLoadOnce && (
        <NotificationSlider
          handleClose={createHandler('NotificationSlider', 'close')}
          isOpen={status.NotificationSlider}
          content={notificationContent}
        />
      )}
      {shouldLoadOnce && (
        <AlertWelcome
          isOpen={status.AlertWelcome}
          handleClose={createHandler('AlertWelcome', 'close')}
        />
      )}
      {shouldLoadOnce && (
        <ModalLogin isOpen={status.ModalLogin} handleClose={createHandler('ModalLogin', 'close')} />
      )}
      {shouldLoadOnce && (
        <ModalJoin isOpen={status.ModalJoin} handleClose={createHandler('ModalJoin', 'close')} />
      )}
      {shouldLoadOnce && (
        <ModalNewsletter
          isOpen={status.ModalNewsletter}
          handleClose={createHandler('ModalNewsletter', 'close')}
        />
      )}
      {shouldLoadOnce && (
        <ModalWelcome
          isOpen={status.ModalWelcome}
          handleClose={createHandler('ModalWelcome', 'close')}
        />
      )}
      {shouldLoadOnce && (
        <ModalYourself
          isOpen={status.ModalYourself}
          handleClose={createHandler('ModalYourself', 'close')}
        />
      )}
      {shouldLoadOnce && (
        <ModalJoinThanks
          isOpen={status.ModalJoinThanks}
          handleClose={createHandler('ModalJoinThanks', 'close')}
          user={user}
        />
      )}
      {shouldLoadOnce && (
        <ModalConfirmation
          isOpen={status.ModalConfirmation}
          handleClose={createHandler('ModalConfirmation', 'close')}
          message={confirmationMessage}
        />
      )}
    </StyledBootstrapScopeWrapper>
  );
};

export default ModalsGroup;
