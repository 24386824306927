/* eslint-disable require-jsdoc */
import {useMemo} from 'react';
import {createStore, applyMiddleware} from 'redux';
import thunkMiddleware from 'redux-thunk';
import callAPIMiddleware from './middlewares/callAPIMiddleware';
import rootReducer from './reducers/rootReducer';

let store: AppStore | undefined;
// eslint-disable-next-line require-jsdoc
function initStore(initialState) {
  const middlewares = [thunkMiddleware, callAPIMiddleware];
  if (process.env.NODE_ENV !== `production`) {
    const {composeWithDevTools} = require('redux-devtools-extension');
    const {logger} = require(`redux-logger`);
    middlewares.push(logger);

    return createStore(
      rootReducer,
      initialState,
      composeWithDevTools(applyMiddleware(...middlewares)),
    );
  }

  const {composeWithDevTools} = require('redux-devtools-extension/logOnly');
  return createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middlewares)),
  );
}

export const initializeStore = (preloadedState) => {
  let _store = store ?? initStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState,
    });
    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
};

export type RootState = ReturnType<AppStore['getState']>;
export type AppStore = ReturnType<typeof initStore>;

// eslint-disable-next-line require-jsdoc
export function useStore(initialState) {
  return useMemo(() => initializeStore(initialState), [initialState]);
}
