import {
  STATUS_FAILED,
  STATUS_SUCCEEDED,
  STATUS_LOADING,
  STATUS_IDLE,
  LOAD_SEO_TEXT_REQUEST,
  LOAD_SEO_TEXT_SUCCESS,
  LOAD_SEO_TEXT_FAILURE,
  SET_SEO_TEXT,
} from '../actionTypes';

const seoTextInitialState = {
  status: STATUS_IDLE,
  error: null,
  data: {
    page_title: null,
    page_description: null,
    meta_page_title: null,
    meta_page_description: null,
  },
};

const plpSeoTextReducer = (state = seoTextInitialState, action) => {
  switch (action.type) {
    case LOAD_SEO_TEXT_REQUEST:
      return {...state, status: STATUS_LOADING};
    case LOAD_SEO_TEXT_SUCCESS:
      return {...state, status: STATUS_SUCCEEDED, data: {...action.response.data}};
    case LOAD_SEO_TEXT_FAILURE:
      return {...state, status: STATUS_FAILED, error: action.error};
    case SET_SEO_TEXT:
      return {...state, data: {...action.payload}};
    default:
      return {...state};
  }
};

export default plpSeoTextReducer;

// Export a reusable selector so state structure is abstracted for components
export const selectPlpSeoTextData = (state) => state.plpSeoText.data;
