/* eslint-disable camelcase */
// Action Types
import {
  SET_ACTIVE_FILTERS,
  SET_CATEGORY_FILTER,
  SET_COLOR_FILTER,
  SET_FRAMED_FILTER,
  SET_KEYWORD_FILTER,
  SET_PRICE_FILTER,
  SET_PRINTS_FILTER,
  SET_SIZE_FILTER,
  SET_SHAPE_FILTER,
  SET_SHIPS_TO_FILTER,
  SET_SHIPS_FROM_FILTER,
  SET_SORT_FILTER,
  SET_STYLE_FILTER,
  SET_SUBJECT_FILTER,
  SET_URL_FILTERS,
  SET_ON_SALE_FILTER,
  SET_FREE_SHIPPING_FILTER,
  RESET_CATEGORY_FILTER,
  RESET_COLOR_FILTER,
  RESET_FRAMED_FILTER,
  RESET_KEYWORD_FILTER,
  RESET_PRICE_FILTER,
  RESET_PRINTS_FILTER,
  RESET_SIZE_FILTER,
  RESET_SHAPE_FILTER,
  RESET_SHIPS_TO_FILTER,
  RESET_SHIPS_FROM_FILTER,
  RESET_STYLE_FILTER,
  RESET_SUBJECT_FILTER,
  RESET_ALL_FILTERS,
  CHANGE_SHIPS_TO_COUNTRY,
  LOAD_PRICE_BARS_REQUEST,
  LOAD_PRICE_BARS_SUCCESS,
  LOAD_PRICE_BARS_FAILURE,
  STATUS_IDLE,
  STATUS_LOADING,
  STATUS_SUCCEEDED,
  STATUS_FAILED,
  SET_PAGINATION_SETTINGS,
} from '../actionTypes';

const sharedFilters: SharedFilters = {
  sort: 'best_match',
  multiple_product_boost: null, // series of id separated by -
  product_boost: null, // single product slug
  price: {
    price_min: null,
    price_max: null,
  },
  size: {
    size_: null, // string
    width_min: null,
    width_max: null,
    height_min: null,
    height_max: null,
  },
  in_sale: null, // on
  free_shipping: null, // true only
  framed_or_ready_to_hang: null,
  has_fine_art_prints: null,
  shape: [],
  q: null, // keyword
  product_category: [],
  category_type: {
    painting: null,
    printmaking: null,
    sculpture: null,
    photography: null,
    drawing: null,
    'digital-art': null,
  },
  colours: [],
  style: [],
  subject: [],
  ships_to: null,
  ships_from: null,
  verified_advertising_safe: null,
  paginate: 60,
  limit: 12,
};

const initialState: InitialState = {
  activeSearch: {
    ...sharedFilters,
  },
  definitions: {
    ...sharedFilters,
  },
  priceBars: {
    status: STATUS_IDLE,
    data: [],
    error: null,
  },
};

const deepSearch = (searchFor, searched) => {
  const searchForKeys = Object.keys(searchFor);

  for (const [key, value] of Object.entries(searched)) {
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      deepSearch(searchFor, value);
    } else if (searchForKeys.includes(key) && searched[key] !== searchFor[key]) {
      searched[key] = searchFor[key];
    }
  }

  return searched;
};

const filterSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CATEGORY_FILTER:
      const productCategories: string[] = [];
      const categoryType = {...sharedFilters.category_type};

      action.payload.map((option) => {
        if (!productCategories.includes(option.value)) productCategories.push(option.value);

        if (
          option.subcategories &&
          option.subcategories.length > 0 &&
          option.value in categoryType
        ) {
          categoryType[option.value] = option.subcategories.map(
            (categoryType) => categoryType.value,
          );
        }
      });

      return {
        ...state,
        definitions: {
          ...state.definitions,
          product_category: productCategories,
          category_type: categoryType,
        },
      };

    case RESET_CATEGORY_FILTER:
      return {
        activeSearch: {
          ...state.activeSearch,
          product_category: [...sharedFilters.product_category],
          category_type: {...sharedFilters.category_type},
        },
        definitions: {
          ...state.definitions,
          product_category: [...sharedFilters.product_category],
          category_type: {...sharedFilters.category_type},
        },
        priceBars: {...state.priceBars},
      };
    case SET_COLOR_FILTER:
      return {
        ...state,
        definitions: {
          ...state.definitions,
          colours: [...action.payload],
        },
      };
    case RESET_COLOR_FILTER:
      return {
        ...state,
        definitions: {
          ...state.definitions,
          colours: sharedFilters.colours,
        },
        activeSearch: {
          ...state.activeSearch,
          colours: sharedFilters.colours,
        },
      };
    case SET_KEYWORD_FILTER:
      return {
        activeSearch: {...state.activeSearch, q: action.payload},
        definitions: {...state.definitions, q: action.payload},
        priceBars: {...state.priceBars},
      };
    case RESET_KEYWORD_FILTER:
      return {
        activeSearch: {...state.activeSearch, q: sharedFilters.q},
        definitions: {...state.definitions, q: sharedFilters.q},
        priceBars: {...state.priceBars},
      };
    case SET_PRICE_FILTER:
      return {...state, definitions: {...state.definitions, price: {...action.payload}}};
    case RESET_PRICE_FILTER:
      return {
        activeSearch: {
          ...state.activeSearch,
          price: {...sharedFilters.price},
          in_sale: sharedFilters.in_sale,
          free_shipping: sharedFilters.free_shipping,
        },
        definitions: {
          ...state.definitions,
          price: {...sharedFilters.price},
          in_sale: sharedFilters.in_sale,
          free_shipping: sharedFilters.free_shipping,
        },
        priceBars: {...state.priceBars},
      };
    case SET_SIZE_FILTER:
      return {
        ...state,
        definitions: {...state.definitions, size: {...action.payload}},
      };
    case RESET_SIZE_FILTER:
      return {
        activeSearch: {...state.activeSearch, size: {...sharedFilters.size}},
        definitions: {...state.definitions, size: {...sharedFilters.size}},
        priceBars: {...state.priceBars},
      };
    case SET_SORT_FILTER:
      return {
        activeSearch: {...state.activeSearch, sort: action.payload},
        definitions: {...state.definitions, sort: action.payload},
        priceBars: {...state.priceBars},
      };
    case SET_STYLE_FILTER:
      return {
        ...state,
        definitions: {
          ...state.definitions,
          style: [...action.payload],
        },
      };
    case RESET_STYLE_FILTER:
      return {
        ...state,
        definitions: {
          ...state.definitions,
          style: sharedFilters.style,
        },
        activeSearch: {
          ...state.activeSearch,
          style: sharedFilters.style,
        },
      };
    case SET_SUBJECT_FILTER:
      const subjectData = action.payload.map((subject) => subject.value);
      return {
        ...state,
        definitions: {
          ...state.definitions,
          subject: subjectData,
        },
      };
    case RESET_SUBJECT_FILTER:
      return {
        ...state,
        definitions: {
          ...state.definitions,
          subject: sharedFilters.subject,
        },
        activeSearch: {
          ...state.activeSearch,
          subject: sharedFilters.subject,
        },
      };
    case SET_PRINTS_FILTER:
      const hasPrints = action.payload === true ? true : null;
      return {
        ...state,
        definitions: {...state.definitions, has_fine_art_prints: hasPrints},
      };
    case RESET_PRINTS_FILTER:
      return {
        activeSearch: {
          ...state.activeSearch,
          has_fine_art_prints: sharedFilters.has_fine_art_prints,
        },
        definitions: {...state.definitions, has_fine_art_prints: sharedFilters.has_fine_art_prints},
        priceBars: {...state.priceBars},
      };
    case SET_ON_SALE_FILTER:
      const inSale = action.payload === true ? 'on' : null;
      return {
        ...state,
        definitions: {...state.definitions, in_sale: inSale},
      };
    case SET_FREE_SHIPPING_FILTER:
      const freeShipping = action.payload.freeShipping ? true : null;
      return {
        ...state,
        definitions: {
          ...state.definitions,
          free_shipping: freeShipping,
          ships_to: action.payload.country,
        },
      };
    case SET_FRAMED_FILTER:
      const framed = action.payload === true ? true : null;
      return {
        ...state,
        definitions: {
          ...state.definitions,
          framed_or_ready_to_hang: framed,
        },
      };
    case RESET_FRAMED_FILTER:
      return {
        ...state,
        definitions: {
          ...state.definitions,
          framed_or_ready_to_hang: sharedFilters.framed_or_ready_to_hang,
        },
        activeSearch: {
          ...state.activeSearch,
          framed_or_ready_to_hang: sharedFilters.framed_or_ready_to_hang,
        },
      };
    case SET_SHAPE_FILTER:
      return {
        ...state,
        definitions: {...state.definitions, shape: [...action.payload]},
      };
    case RESET_SHAPE_FILTER:
      return {
        activeSearch: {
          ...state.activeSearch,
          shape: sharedFilters.shape,
        },
        definitions: {...state.definitions, shape: sharedFilters.shape},
        priceBars: {...state.priceBars},
      };
    case SET_SHIPS_TO_FILTER:
      if (action.payload.country) {
        return {
          ...state,
          definitions: {
            ...state.definitions,
            ships_to: action.payload.country,
          },
        };
      } else {
        return {
          ...state,
          definitions: {
            ...state.definitions,
            ships_to: action.payload.country,
            free_shipping: sharedFilters.free_shipping,
          },
        };
      }
    case RESET_SHIPS_TO_FILTER:
      return {
        ...state,
        activeSearch: {
          ...state.activeSearch,
          ships_to: sharedFilters.ships_to,
        },
        definitions: {
          ...state.definitions,
          ships_to: sharedFilters.ships_to,
        },
      };
    case CHANGE_SHIPS_TO_COUNTRY:
      const newState = {
        ...state,
      };

      if (
        newState.activeSearch.ships_to &&
        newState.activeSearch.ships_to !== action.payload.country
      ) {
        newState.activeSearch = {...state.activeSearch, ships_to: action.payload.country};
      }

      if (
        newState.definitions.ships_to &&
        newState.definitions.ships_to !== action.payload.country
      ) {
        newState.definitions = {...state.definitions, ships_to: action.payload.country};
      }

      return newState;
    case SET_SHIPS_FROM_FILTER:
      return {
        ...state,
        definitions: {
          ...state.definitions,
          ships_from: action.payload.country,
        },
      };
    case RESET_SHIPS_FROM_FILTER:
      return {
        ...state,
        activeSearch: {
          ...state.activeSearch,
          ships_from: sharedFilters.ships_from,
        },
        definitions: {
          ...state.definitions,
          ships_from: sharedFilters.ships_from,
        },
      };
    case SET_URL_FILTERS:
      const urlFilters = deepSearch(action.payload, JSON.parse(JSON.stringify(sharedFilters)));
      return {
        activeSearch: {...urlFilters},
        definitions: {...urlFilters},
        priceBars: {...state.priceBars},
      };
    case RESET_ALL_FILTERS:
      return {
        activeSearch: {
          ...initialState.activeSearch,
          sort: state.activeSearch.sort,
          paginate: state.activeSearch.paginate,
          limit: state.activeSearch.limit,
        },
        definitions: {
          ...initialState.definitions,
          sort: state.definitions.sort,
          paginate: state.definitions.paginate,
          limit: state.definitions.limit,
        },
        priceBars: {...initialState.priceBars},
      };
    case SET_ACTIVE_FILTERS:
      return {...state, activeSearch: {...state.definitions}};
    case LOAD_PRICE_BARS_REQUEST:
      return {...state, priceBars: {...state.priceBars, status: STATUS_LOADING}};
    case LOAD_PRICE_BARS_SUCCESS:
      return {
        ...state,
        priceBars: {status: STATUS_SUCCEEDED, data: action.response.data.results, error: null},
      };
    case LOAD_PRICE_BARS_FAILURE:
      return {
        ...state,
        priceBars: {...state.priceBars, status: STATUS_FAILED, error: action.error},
      };
    case SET_PAGINATION_SETTINGS:
      return {
        ...state,
        activeSearch: {...state.activeSearch, ...action.payload},
        definitions: {...state.definitions, ...action.payload},
      };
    default:
      return {...state};
  }
};

export default filterSettingsReducer;
export const selectFilterSettings = (state): SharedFilters => state.filterSettings.definitions;
export const selectSearchSettings = (state): SharedFilters => state.filterSettings.activeSearch;
export const selectPriceBarsData = (state): PriceBarsData[] => state.filterSettings.priceBars.data;

export interface CategoryType {
  painting?: string[] | null;
  printmaking?: string[] | null;
  sculpture?: string[] | null;
  photography?: string[] | null;
  drawing?: string[] | null;
  'digital-art'?: string[] | null;
}

interface Size {
  size_: 'small' | 'medium' | 'large' | 'xlarge' | null;
  width_min: number | null;
  width_max: number | null;
  height_min: number | null;
  height_max: number | null;
}

interface Price {
  price_min: number | null;
  price_max: number | null;
}

interface SharedFilters {
  sort: string;
  multiple_product_boost: string | null;
  product_boost?: string | null; // single product slug
  price: Price;
  size: Size;
  in_sale: 'on' | null;
  free_shipping: boolean | null;
  framed_or_ready_to_hang: boolean | null;
  has_fine_art_prints: boolean | null;
  shape: string[];
  q: string | null;
  product_category: string[];
  category_type: CategoryType;
  colours: string[];
  style: string[];
  subject: string[];
  ships_to: string | null;
  ships_from: string | null;
  verified_advertising_safe: boolean | null;
  paginate: number;
  limit: number;
}

interface PriceBarsData {
  from_price: number;
  to_price?: number | null;
  items_count: number;
  price_avg?: number | null;
  currency: string;
}

interface PriceBars {
  status: string;
  data: PriceBarsData[];
  error?: string | null;
}

interface InitialState {
  activeSearch: SharedFilters;
  definitions: SharedFilters;
  priceBars: PriceBars;
}
