import type {RecentlyViewedItem} from '@/molecules/Fragments/Sections/RecentlyViewed/typings.d';
import type {RootState} from '../store';
import type {StatusType, ReduxAction} from './typings';

import {
  STATUS_IDLE,
  STATUS_LOADING,
  STATUS_SUCCEEDED,
  STATUS_FAILED,
  GET_RECENTLY_VIEWED_SUCCESS,
  GET_RECENTLY_VIEWED_REQUEST,
  GET_RECENTLY_VIEWED_FAILURE,
} from '../actionTypes';

type RecentlyViewedActionTypes =
  | typeof GET_RECENTLY_VIEWED_REQUEST
  | typeof GET_RECENTLY_VIEWED_SUCCESS
  | typeof GET_RECENTLY_VIEWED_FAILURE;

export interface RecentlyViewedProductsState {
  status: StatusType;
  data: RecentlyViewedItem[];
  error: null | string;
}

export const initialState: RecentlyViewedProductsState = {
  status: STATUS_IDLE,
  data: [],
  error: null,
};

const recentlyViewedProductsReducer = (
  state = initialState,
  action: ReduxAction<RecentlyViewedItem[], RecentlyViewedActionTypes>,
) => {
  switch (action.type) {
    case GET_RECENTLY_VIEWED_REQUEST:
      return {
        ...state,
        status: STATUS_LOADING,
        error: null,
      };

    case GET_RECENTLY_VIEWED_SUCCESS:
      return {
        ...state,
        status: STATUS_SUCCEEDED,
        data: action.response?.data as RecentlyViewedItem[],
      };

    case GET_RECENTLY_VIEWED_FAILURE:
      return {
        ...state,
        status: STATUS_FAILED,
        error: action.error?.message as string,
      };

    default:
      return state;
  }
};

export const getRecentlyViewedProducts = (state: RootState) => state.recentlyViewedProducts;

export default recentlyViewedProductsReducer;
