import {
  STATUS_FAILED,
  STATUS_SUCCEEDED,
  STATUS_LOADING,
  STATUS_IDLE,
  GET_PRODUCT_THEMES_REQUEST,
  GET_PRODUCT_THEMES_SUCCESS,
  GET_PRODUCT_THEMES_FAILURE,
  ADD_PRODUCT_THEME_REQUEST,
  ADD_PRODUCT_THEME_FAILURE,
  DELETE_PRODUCT_THEME_REQUEST,
  DELETE_PRODUCT_THEME_FAILURE,
  GET_PRODUCT_CAMPAIGNS_REQUEST,
  GET_PRODUCT_CAMPAIGNS_SUCCESS,
  GET_PRODUCT_CAMPAIGNS_FAILURE,
  ADD_PRODUCT_CAMPAIGN_REQUEST,
  ADD_PRODUCT_CAMPAIGN_FAILURE,
  DELETE_PRODUCT_CAMPAIGN_REQUEST,
  DELETE_PRODUCT_CAMPAIGN_FAILURE,
  GET_PRODUCT_COLLECTIONS_REQUEST,
  GET_PRODUCT_COLLECTIONS_SUCCESS,
  GET_PRODUCT_COLLECTIONS_FAILURE,
  ADD_PRODUCT_COLLECTION_REQUEST,
  ADD_PRODUCT_COLLECTION_FAILURE,
  DELETE_PRODUCT_COLLECTION_FAILURE,
  DELETE_PRODUCT_COLLECTION_REQUEST,
  CREATE_NEW_COLLECTION_SUCCESS,
} from '../actionTypes';

const initialState = {
  themes: {
    status: STATUS_IDLE,
    error: null,
    data: [],
  },
  collections: {
    status: STATUS_IDLE,
    error: null,
    data: [],
  },
  campaigns: {
    status: STATUS_IDLE,
    error: null,
    data: [],
  },
};

const staffOnlyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCT_THEMES_REQUEST:
      return {...state, themes: {...state.themes, status: STATUS_LOADING}};
    case GET_PRODUCT_THEMES_SUCCESS:
      return {
        ...state,
        themes: {
          status: STATUS_SUCCEEDED,
          data: {...state.themes.data, [action.slug]: [...action.response.data]},
          error: null,
        },
      };
    case GET_PRODUCT_THEMES_FAILURE:
      return {
        ...state,
        themes: {status: STATUS_FAILED, data: {...state.themes.data}, error: action.error},
      };
    case DELETE_PRODUCT_THEME_FAILURE:
    case ADD_PRODUCT_THEME_REQUEST:
      return {
        ...state,
        themes: {
          ...state.themes,
          data: {
            ...state.themes.data,
            [action.slug]: state.themes.data[action.slug].map((el) => {
              if (el.id_for_label === action.themeId) return {...el, value: true};
              return el;
            }),
          },
        },
      };
    case ADD_PRODUCT_THEME_FAILURE:
    case DELETE_PRODUCT_THEME_REQUEST:
      return {
        ...state,
        themes: {
          ...state.themes,
          data: {
            ...state.themes.data,
            [action.slug]: state.themes.data[action.slug].map((el) => {
              if (el.id_for_label === action.themeId) return {...el, value: false};
              return el;
            }),
          },
        },
      };
    case GET_PRODUCT_CAMPAIGNS_REQUEST:
      return {...state, campaigns: {...state.campaigns, status: STATUS_LOADING}};
    case GET_PRODUCT_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaigns: {
          status: STATUS_SUCCEEDED,
          data: {...state.campaigns.data, [action.slug]: [...action.response.data]},
          error: null,
        },
      };
    case GET_PRODUCT_CAMPAIGNS_FAILURE:
      return {
        ...state,
        campaigns: {status: STATUS_FAILED, data: {...state.campaigns.data}, error: action.error},
      };
    case DELETE_PRODUCT_CAMPAIGN_FAILURE:
    case ADD_PRODUCT_CAMPAIGN_REQUEST:
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          data: {
            ...state.campaigns.data,
            [action.slug]: state.campaigns.data[action.slug].map((el) => {
              if (el.id_for_label === action.campaignId) return {...el, value: true};
              return el;
            }),
          },
        },
      };
    case ADD_PRODUCT_CAMPAIGN_FAILURE:
    case DELETE_PRODUCT_CAMPAIGN_REQUEST:
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          data: {
            ...state.campaigns.data,
            [action.slug]: state.campaigns.data[action.slug].map((el) => {
              if (el.id_for_label === action.campaignId) return {...el, value: false};
              return el;
            }),
          },
        },
      };
    case GET_PRODUCT_COLLECTIONS_REQUEST:
      return {...state, collections: {...state.collections, status: STATUS_LOADING}};
    case GET_PRODUCT_COLLECTIONS_SUCCESS:
      return {
        ...state,
        collections: {
          status: STATUS_SUCCEEDED,
          data: {...state.collections.data, [action.slug]: [...action.response.data]},
          error: null,
        },
      };
    case GET_PRODUCT_COLLECTIONS_FAILURE:
      return {
        ...state,
        collections: {
          status: STATUS_FAILED,
          data: {...state.collections.data},
          error: action.error,
        },
      };
    case DELETE_PRODUCT_COLLECTION_FAILURE:
    case ADD_PRODUCT_COLLECTION_REQUEST:
      return {
        ...state,
        collections: {
          ...state.collections,
          data: {
            ...state.collections.data,
            [action.slug]: state.collections.data[action.slug].map((el) => {
              if (el.id === action.collectionId)
                return {...el, is_product_in_collection: true, total_items: el.total_items + 1};
              return el;
            }),
          },
        },
      };
    case ADD_PRODUCT_COLLECTION_FAILURE:
    case DELETE_PRODUCT_COLLECTION_REQUEST:
      return {
        ...state,
        collections: {
          ...state.collections,
          data: {
            ...state.collections.data,
            [action.slug]: state.collections.data[action.slug].map((el) => {
              if (el.id === action.collectionId)
                return {...el, is_product_in_collection: false, total_items: el.total_items - 1};
              return el;
            }),
          },
        },
      };
    case CREATE_NEW_COLLECTION_SUCCESS:
      const updatedCollections = {...state.collections.data};
      delete updatedCollections[action.slug];
      return {...state, collections: {...state.collections, data: {...updatedCollections}}};
    default:
      return {...state};
  }
};

export default staffOnlyReducer;

export const selectThemes = (state) => state.staffOnly.themes;
export const selectCampaigns = (state) => state.staffOnly.campaigns;
export const selectCollections = (state) => state.staffOnly.collections;
