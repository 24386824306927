import styled, {createGlobalStyle} from 'styled-components';
import colors from '../src/atomic-design/theme/constants/colors';
import {StyledSectionProps} from './typings';
import container from '@/theme/constants/container';
import {sizePx} from '@/theme/helpers/size';
import {cssNormalize} from '@/theme/cssNormalize';
import {globals} from '@/theme/globals';

export const GlobalStyle = createGlobalStyle`
  ${cssNormalize}
  ${globals}
`;

const sectionVerticalPadding = {
  l: 32,
  m: 20,
  s: 12,
  xs: 0,
};

export const StyledPage = styled.div`
  background: ${colors.white};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const StyledColorBlock = styled.section<StyledSectionProps>`
  background: ${(props) => props.$background ?? colors.white};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${(props) =>
      props.$verticalPadding
        ? sizePx(sectionVerticalPadding[props.$verticalPadding])
        : sizePx(sectionVerticalPadding.l)}
    16px;
  overflow: ${(props) => (props.$overflow ? 'visible' : 'hidden')};

  > * {
    max-width: ${container.maxWidth};
    width: 100%;
  }
`;

export default StyledPage;
