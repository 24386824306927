import styled, {css} from 'styled-components';
import breakpoints from '@/theme/constants/breakpoints';

const bootstrapStyles = css`
  .header-hero {
    font-family: 'boing', helvetica, sans-serif;
    font-size: 2.25rem;
    font-weight: normal;
    line-height: 2.375rem;
    color: #15212f;
  }
  .header-hero.accent {
    color: #ff1749;
  }
  .header-hero.secondary {
    color: #d7d2cb;
  }
  .header-hero.white {
    color: #fff;
  }
  .header-hero.truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .header-hero.center {
    text-align: center;
  }
  .header-hero.right {
    text-align: right;
  }
  .header-hero.left {
    text-align: left;
  }

  .header-main {
    font-family: 'boing', helvetica, sans-serif;
    font-size: 1.875rem;
    font-weight: normal;
    line-height: 2rem;
    color: #15212f;
  }
  .header-main.accent {
    color: #ff1749;
  }
  .header-main.secondary {
    color: #d7d2cb;
  }
  .header-main.white {
    color: #fff;
  }
  .header-main.truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .header-main.center {
    text-align: center;
  }
  .header-main.right {
    text-align: right;
  }
  .header-main.left {
    text-align: left;
  }

  .header-section {
    font-family: 'boing', helvetica, sans-serif;
    font-size: 1.5rem;
    font-weight: normal;
    line-height: 1.625rem;
    color: #15212f;
  }
  .header-section.accent {
    color: #ff1749;
  }
  .header-section.secondary {
    color: #d7d2cb;
  }
  .header-section.white {
    color: #fff;
  }
  .header-section.truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .header-section.center {
    text-align: center;
  }
  .header-section.right {
    text-align: right;
  }
  .header-section.left {
    text-align: left;
  }

  .header-title {
    font-family: 'boing', helvetica, sans-serif;
    font-size: 1.25rem;
    font-weight: normal;
    line-height: 1.375rem;
    color: #15212f;
  }
  .header-title.accent {
    color: #ff1749;
  }
  .header-title.secondary {
    color: #d7d2cb;
  }
  .header-title.white {
    color: #fff;
  }
  .header-title.truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .header-title.center {
    text-align: center;
  }
  .header-title.right {
    text-align: right;
  }
  .header-title.left {
    text-align: left;
  }

  .header-art {
    font-size: 1.125rem;
    font-weight: bold;
    line-height: 1.25rem;
    color: #15212f;
  }
  .header-art.accent {
    color: #ff1749;
  }
  .header-art.secondary {
    color: #d7d2cb;
  }
  .header-art.white {
    color: #fff;
  }
  .header-art.truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .header-art.center {
    text-align: center;
  }
  .header-art.right {
    text-align: right;
  }
  .header-art.left {
    text-align: left;
  }

  .header-highlights {
    font-family: 'boing', helvetica, sans-serif;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.125rem;
    color: #15212f;
  }
  .header-highlights.accent {
    color: #ff1749;
  }
  .header-highlights.secondary {
    color: #d7d2cb;
  }
  .header-highlights.white {
    color: #fff;
  }
  .header-highlights.truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .header-highlights.center {
    text-align: center;
  }
  .header-highlights.right {
    text-align: right;
  }
  .header-highlights.left {
    text-align: left;
  }

  @media (min-width: 576px) {
    .header-hero {
      font-family: 'boing', helvetica, sans-serif;
      font-size: 3rem;
      font-weight: normal;
      line-height: 3.125rem;
      color: #15212f;
    }
    .header-hero.accent {
      color: #ff1749;
    }
    .header-hero.secondary {
      color: #d7d2cb;
    }
    .header-hero.white {
      color: #fff;
    }
    .header-hero.truncate {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .header-hero.center {
      text-align: center;
    }
    .header-hero.right {
      text-align: right;
    }
    .header-hero.left {
      text-align: left;
    }
    .header-main {
      font-family: 'boing', helvetica, sans-serif;
      font-size: 2.375rem;
      font-weight: normal;
      line-height: 2.5rem;
      color: #15212f;
    }
    .header-main.accent {
      color: #ff1749;
    }
    .header-main.secondary {
      color: #d7d2cb;
    }
    .header-main.white {
      color: #fff;
    }
    .header-main.truncate {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .header-main.center {
      text-align: center;
    }
    .header-main.right {
      text-align: right;
    }
    .header-main.left {
      text-align: left;
    }
  }
  @media (min-width: 992px) {
    .header-hero {
      font-family: 'boing', helvetica, sans-serif;
      font-size: 3.75rem;
      font-weight: normal;
      line-height: 3.875rem;
      color: #15212f;
    }
    .header-hero.accent {
      color: #ff1749;
    }
    .header-hero.secondary {
      color: #d7d2cb;
    }
    .header-hero.white {
      color: #fff;
    }
    .header-hero.truncate {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .header-hero.center {
      text-align: center;
    }
    .header-hero.right {
      text-align: right;
    }
    .header-hero.left {
      text-align: left;
    }
    .header-main {
      font-family: 'boing', helvetica, sans-serif;
      font-size: 3rem;
      font-weight: normal;
      line-height: 3.125rem;
      color: #15212f;
    }
    .header-main.accent {
      color: #ff1749;
    }
    .header-main.secondary {
      color: #d7d2cb;
    }
    .header-main.white {
      color: #fff;
    }
    .header-main.truncate {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .header-main.center {
      text-align: center;
    }
    .header-main.right {
      text-align: right;
    }
    .header-main.left {
      text-align: left;
    }
    .header-section {
      font-family: 'boing', helvetica, sans-serif;
      font-size: 2rem;
      font-weight: normal;
      line-height: 2.188rem;
      color: #15212f;
    }
    .header-section.accent {
      color: #ff1749;
    }
    .header-section.secondary {
      color: #d7d2cb;
    }
    .header-section.white {
      color: #fff;
    }
    .header-section.truncate {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .header-section.center {
      text-align: center;
    }
    .header-section.right {
      text-align: right;
    }
    .header-section.left {
      text-align: left;
    }
    .header-title {
      font-family: 'boing', helvetica, sans-serif;
      font-size: 1.625rem;
      font-weight: normal;
      line-height: 1.75rem;
      color: #15212f;
    }
    .header-title.accent {
      color: #ff1749;
    }
    .header-title.secondary {
      color: #d7d2cb;
    }
    .header-title.white {
      color: #fff;
    }
    .header-title.truncate {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .header-title.center {
      text-align: center;
    }
    .header-title.right {
      text-align: right;
    }
    .header-title.left {
      text-align: left;
    }
    .header-art {
      font-size: 1.25rem;
      font-weight: bold;
      line-height: 1.563rem;
      color: #15212f;
    }
    .header-art.accent {
      color: #ff1749;
    }
    .header-art.secondary {
      color: #d7d2cb;
    }
    .header-art.white {
      color: #fff;
    }
    .header-art.truncate {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .header-art.center {
      text-align: center;
    }
    .header-art.right {
      text-align: right;
    }
    .header-art.left {
      text-align: left;
    }
    .header-highlights {
      font-family: 'boing', helvetica, sans-serif;
      font-size: 1.25rem;
      font-weight: normal;
      line-height: 1.563rem;
      color: #15212f;
    }
    .header-highlights.accent {
      color: #ff1749;
    }
    .header-highlights.secondary {
      color: #d7d2cb;
    }
    .header-highlights.white {
      color: #fff;
    }
    .header-highlights.truncate {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .header-highlights.center {
      text-align: center;
    }
    .header-highlights.right {
      text-align: right;
    }
    .header-highlights.left {
      text-align: left;
    }
  }
  .body1 {
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.625rem;
    color: #15212f;
  }
  .body1.accent {
    color: #ff1749;
  }
  .body1.secondary {
    color: #d7d2cb;
  }
  .body1.white {
    color: #fff;
  }
  .body1.truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .body1.center {
    text-align: center;
  }
  .body1.right {
    text-align: right;
  }
  .body1.left {
    text-align: left;
  }

  .body2 {
    font-size: 0.875rem;
    font-weight: normal;
    line-height: 1.375rem;
    color: #15212f;
  }
  .body2.accent {
    color: #ff1749;
  }
  .body2.secondary {
    color: #d7d2cb;
  }
  .body2.white {
    color: #fff;
  }
  .body2.truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .body2.center {
    text-align: center;
  }
  .body2.right {
    text-align: right;
  }
  .body2.left {
    text-align: left;
  }

  .body3 {
    font-size: 0.75rem;
    font-weight: normal;
    line-height: 1.25rem;
    color: #15212f;
  }
  .body3.accent {
    color: #ff1749;
  }
  .body3.secondary {
    color: #d7d2cb;
  }
  .body3.white {
    color: #fff;
  }
  .body3.truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .body3.center {
    text-align: center;
  }
  .body3.right {
    text-align: right;
  }
  .body3.left {
    text-align: left;
  }

  .subtitle1 {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.625rem;
    color: #15212f;
  }
  .subtitle1.accent {
    color: #ff1749;
  }
  .subtitle1.secondary {
    color: #d7d2cb;
  }
  .subtitle1.white {
    color: #fff;
  }
  .subtitle1.truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .subtitle1.center {
    text-align: center;
  }
  .subtitle1.right {
    text-align: right;
  }
  .subtitle1.left {
    text-align: left;
  }

  .subtitle2 {
    font-size: 0.875rem;
    font-weight: bold;
    line-height: 1.375rem;
    color: #15212f;
  }
  .subtitle2.accent {
    color: #ff1749;
  }
  .subtitle2.secondary {
    color: #d7d2cb;
  }
  .subtitle2.white {
    color: #fff;
  }
  .subtitle2.truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .subtitle2.center {
    text-align: center;
  }
  .subtitle2.right {
    text-align: right;
  }
  .subtitle2.left {
    text-align: left;
  }

  .subtitle3 {
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 1.25rem;
    color: #15212f;
  }
  .subtitle3.accent {
    color: #ff1749;
  }
  .subtitle3.secondary {
    color: #d7d2cb;
  }
  .subtitle3.white {
    color: #fff;
  }
  .subtitle3.truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .subtitle3.center {
    text-align: center;
  }
  .subtitle3.right {
    text-align: right;
  }
  .subtitle3.left {
    text-align: left;
  }

  .lead {
    font-size: 1.25rem;
    font-weight: 300;
  }

  .display-1 {
    font-size: 6rem;
    font-weight: 300;
    line-height: 3rem;
  }

  .display-2 {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 3rem;
  }

  .display-3 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 3rem;
  }

  .display-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 3rem;
  }

  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  small,
  .small {
    font-size: 80%;
    font-weight: 400;
  }

  mark,
  .mark {
    padding: 0.2em;
    background-color: #fcf8e3;
  }

  .list-unstyled {
    padding-left: 0;
    list-style: none;
  }

  .list-inline {
    padding-left: 0;
    list-style: none;
  }

  .list-inline-item {
    display: inline-block;
  }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
  }

  .initialism {
    font-size: 90%;
    text-transform: uppercase;
  }

  .blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem;
  }

  .blockquote-footer {
    display: block;
    font-size: 80%;
    color: #6c757d;
  }
  .blockquote-footer::before {
    content: '— ';
  }

  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  @media (min-width: 576px) {
    .container {
      max-width: 540px;
    }
  }
  @media (min-width: 768px) {
    .container {
      max-width: 720px;
    }
  }
  @media (min-width: 992px) {
    .container {
      max-width: 960px;
    }
  }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px;
    }
  }

  .container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .no-gutters {
    margin-right: 0;
    margin-left: 0;
  }
  .no-gutters > .col,
  .no-gutters > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
  }

  .col-xl,
  .col-xl-auto,
  .col-xl-12,
  .col-xl-11,
  .col-xl-10,
  .col-xl-9,
  .col-xl-8,
  .col-xl-7,
  .col-xl-6,
  .col-xl-5,
  .col-xl-4,
  .col-xl-3,
  .col-xl-2,
  .col-xl-1,
  .col-lg,
  .col-lg-auto,
  .col-lg-12,
  .col-lg-11,
  .col-lg-10,
  .col-lg-9,
  .col-lg-8,
  .col-lg-7,
  .col-lg-6,
  .col-lg-5,
  .col-lg-4,
  .col-lg-3,
  .col-lg-2,
  .col-lg-1,
  .col-md,
  .col-md-auto,
  .col-md-12,
  .col-md-11,
  .col-md-10,
  .col-md-9,
  .col-md-8,
  .col-md-7,
  .col-md-6,
  .col-md-5,
  .col-md-4,
  .col-md-3,
  .col-md-2,
  .col-md-1,
  .col-sm,
  .col-sm-auto,
  .col-sm-12,
  .col-sm-11,
  .col-sm-10,
  .col-sm-9,
  .col-sm-8,
  .col-sm-7,
  .col-sm-6,
  .col-sm-5,
  .col-sm-4,
  .col-sm-3,
  .col-sm-2,
  .col-sm-1,
  .col,
  .col-auto,
  .col-12,
  .col-11,
  .col-10,
  .col-9,
  .col-8,
  .col-7,
  .col-6,
  .col-5,
  .col-4,
  .col-3,
  .col-2,
  .col-1 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }

  .col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-first {
    order: -1;
  }

  .order-last {
    order: 13;
  }

  .order-0 {
    order: 0;
  }

  .order-1 {
    order: 1;
  }

  .order-2 {
    order: 2;
  }

  .order-3 {
    order: 3;
  }

  .order-4 {
    order: 4;
  }

  .order-5 {
    order: 5;
  }

  .order-6 {
    order: 6;
  }

  .order-7 {
    order: 7;
  }

  .order-8 {
    order: 8;
  }

  .order-9 {
    order: 9;
  }

  .order-10 {
    order: 10;
  }

  .order-11 {
    order: 11;
  }

  .order-12 {
    order: 12;
  }

  .offset-1 {
    margin-left: 8.3333333333%;
  }

  .offset-2 {
    margin-left: 16.6666666667%;
  }

  .offset-3 {
    margin-left: 25%;
  }

  .offset-4 {
    margin-left: 33.3333333333%;
  }

  .offset-5 {
    margin-left: 41.6666666667%;
  }

  .offset-6 {
    margin-left: 50%;
  }

  .offset-7 {
    margin-left: 58.3333333333%;
  }

  .offset-8 {
    margin-left: 66.6666666667%;
  }

  .offset-9 {
    margin-left: 75%;
  }

  .offset-10 {
    margin-left: 83.3333333333%;
  }

  .offset-11 {
    margin-left: 91.6666666667%;
  }

  @media (min-width: 576px) {
    .col-sm {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .col-sm-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .col-sm-1 {
      flex: 0 0 8.3333333333%;
      max-width: 8.3333333333%;
    }
    .col-sm-2 {
      flex: 0 0 16.6666666667%;
      max-width: 16.6666666667%;
    }
    .col-sm-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-sm-4 {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%;
    }
    .col-sm-5 {
      flex: 0 0 41.6666666667%;
      max-width: 41.6666666667%;
    }
    .col-sm-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-sm-7 {
      flex: 0 0 58.3333333333%;
      max-width: 58.3333333333%;
    }
    .col-sm-8 {
      flex: 0 0 66.6666666667%;
      max-width: 66.6666666667%;
    }
    .col-sm-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-sm-10 {
      flex: 0 0 83.3333333333%;
      max-width: 83.3333333333%;
    }
    .col-sm-11 {
      flex: 0 0 91.6666666667%;
      max-width: 91.6666666667%;
    }
    .col-sm-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-sm-first {
      order: -1;
    }
    .order-sm-last {
      order: 13;
    }
    .order-sm-0 {
      order: 0;
    }
    .order-sm-1 {
      order: 1;
    }
    .order-sm-2 {
      order: 2;
    }
    .order-sm-3 {
      order: 3;
    }
    .order-sm-4 {
      order: 4;
    }
    .order-sm-5 {
      order: 5;
    }
    .order-sm-6 {
      order: 6;
    }
    .order-sm-7 {
      order: 7;
    }
    .order-sm-8 {
      order: 8;
    }
    .order-sm-9 {
      order: 9;
    }
    .order-sm-10 {
      order: 10;
    }
    .order-sm-11 {
      order: 11;
    }
    .order-sm-12 {
      order: 12;
    }
    .offset-sm-0 {
      margin-left: 0;
    }
    .offset-sm-1 {
      margin-left: 8.3333333333%;
    }
    .offset-sm-2 {
      margin-left: 16.6666666667%;
    }
    .offset-sm-3 {
      margin-left: 25%;
    }
    .offset-sm-4 {
      margin-left: 33.3333333333%;
    }
    .offset-sm-5 {
      margin-left: 41.6666666667%;
    }
    .offset-sm-6 {
      margin-left: 50%;
    }
    .offset-sm-7 {
      margin-left: 58.3333333333%;
    }
    .offset-sm-8 {
      margin-left: 66.6666666667%;
    }
    .offset-sm-9 {
      margin-left: 75%;
    }
    .offset-sm-10 {
      margin-left: 83.3333333333%;
    }
    .offset-sm-11 {
      margin-left: 91.6666666667%;
    }
  }
  @media (min-width: 768px) {
    .col-md {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .col-md-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .col-md-1 {
      flex: 0 0 8.3333333333%;
      max-width: 8.3333333333%;
    }
    .col-md-2 {
      flex: 0 0 16.6666666667%;
      max-width: 16.6666666667%;
    }
    .col-md-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-md-4 {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%;
    }
    .col-md-5 {
      flex: 0 0 41.6666666667%;
      max-width: 41.6666666667%;
    }
    .col-md-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-md-7 {
      flex: 0 0 58.3333333333%;
      max-width: 58.3333333333%;
    }
    .col-md-8 {
      flex: 0 0 66.6666666667%;
      max-width: 66.6666666667%;
    }
    .col-md-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-md-10 {
      flex: 0 0 83.3333333333%;
      max-width: 83.3333333333%;
    }
    .col-md-11 {
      flex: 0 0 91.6666666667%;
      max-width: 91.6666666667%;
    }
    .col-md-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-md-first {
      order: -1;
    }
    .order-md-last {
      order: 13;
    }
    .order-md-0 {
      order: 0;
    }
    .order-md-1 {
      order: 1;
    }
    .order-md-2 {
      order: 2;
    }
    .order-md-3 {
      order: 3;
    }
    .order-md-4 {
      order: 4;
    }
    .order-md-5 {
      order: 5;
    }
    .order-md-6 {
      order: 6;
    }
    .order-md-7 {
      order: 7;
    }
    .order-md-8 {
      order: 8;
    }
    .order-md-9 {
      order: 9;
    }
    .order-md-10 {
      order: 10;
    }
    .order-md-11 {
      order: 11;
    }
    .order-md-12 {
      order: 12;
    }
    .offset-md-0 {
      margin-left: 0;
    }
    .offset-md-1 {
      margin-left: 8.3333333333%;
    }
    .offset-md-2 {
      margin-left: 16.6666666667%;
    }
    .offset-md-3 {
      margin-left: 25%;
    }
    .offset-md-4 {
      margin-left: 33.3333333333%;
    }
    .offset-md-5 {
      margin-left: 41.6666666667%;
    }
    .offset-md-6 {
      margin-left: 50%;
    }
    .offset-md-7 {
      margin-left: 58.3333333333%;
    }
    .offset-md-8 {
      margin-left: 66.6666666667%;
    }
    .offset-md-9 {
      margin-left: 75%;
    }
    .offset-md-10 {
      margin-left: 83.3333333333%;
    }
    .offset-md-11 {
      margin-left: 91.6666666667%;
    }
  }
  @media (min-width: 992px) {
    .col-lg {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .col-lg-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .col-lg-1 {
      flex: 0 0 8.3333333333%;
      max-width: 8.3333333333%;
    }
    .col-lg-2 {
      flex: 0 0 16.6666666667%;
      max-width: 16.6666666667%;
    }
    .col-lg-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-lg-4 {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%;
    }
    .col-lg-5 {
      flex: 0 0 41.6666666667%;
      max-width: 41.6666666667%;
    }
    .col-lg-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-lg-7 {
      flex: 0 0 58.3333333333%;
      max-width: 58.3333333333%;
    }
    .col-lg-8 {
      flex: 0 0 66.6666666667%;
      max-width: 66.6666666667%;
    }
    .col-lg-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-lg-10 {
      flex: 0 0 83.3333333333%;
      max-width: 83.3333333333%;
    }
    .col-lg-11 {
      flex: 0 0 91.6666666667%;
      max-width: 91.6666666667%;
    }
    .col-lg-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-lg-first {
      order: -1;
    }
    .order-lg-last {
      order: 13;
    }
    .order-lg-0 {
      order: 0;
    }
    .order-lg-1 {
      order: 1;
    }
    .order-lg-2 {
      order: 2;
    }
    .order-lg-3 {
      order: 3;
    }
    .order-lg-4 {
      order: 4;
    }
    .order-lg-5 {
      order: 5;
    }
    .order-lg-6 {
      order: 6;
    }
    .order-lg-7 {
      order: 7;
    }
    .order-lg-8 {
      order: 8;
    }
    .order-lg-9 {
      order: 9;
    }
    .order-lg-10 {
      order: 10;
    }
    .order-lg-11 {
      order: 11;
    }
    .order-lg-12 {
      order: 12;
    }
    .offset-lg-0 {
      margin-left: 0;
    }
    .offset-lg-1 {
      margin-left: 8.3333333333%;
    }
    .offset-lg-2 {
      margin-left: 16.6666666667%;
    }
    .offset-lg-3 {
      margin-left: 25%;
    }
    .offset-lg-4 {
      margin-left: 33.3333333333%;
    }
    .offset-lg-5 {
      margin-left: 41.6666666667%;
    }
    .offset-lg-6 {
      margin-left: 50%;
    }
    .offset-lg-7 {
      margin-left: 58.3333333333%;
    }
    .offset-lg-8 {
      margin-left: 66.6666666667%;
    }
    .offset-lg-9 {
      margin-left: 75%;
    }
    .offset-lg-10 {
      margin-left: 83.3333333333%;
    }
    .offset-lg-11 {
      margin-left: 91.6666666667%;
    }
  }
  @media (min-width: 1200px) {
    .col-xl {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .col-xl-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .col-xl-1 {
      flex: 0 0 8.3333333333%;
      max-width: 8.3333333333%;
    }
    .col-xl-2 {
      flex: 0 0 16.6666666667%;
      max-width: 16.6666666667%;
    }
    .col-xl-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-xl-4 {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%;
    }
    .col-xl-5 {
      flex: 0 0 41.6666666667%;
      max-width: 41.6666666667%;
    }
    .col-xl-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-xl-7 {
      flex: 0 0 58.3333333333%;
      max-width: 58.3333333333%;
    }
    .col-xl-8 {
      flex: 0 0 66.6666666667%;
      max-width: 66.6666666667%;
    }
    .col-xl-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-xl-10 {
      flex: 0 0 83.3333333333%;
      max-width: 83.3333333333%;
    }
    .col-xl-11 {
      flex: 0 0 91.6666666667%;
      max-width: 91.6666666667%;
    }
    .col-xl-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-xl-first {
      order: -1;
    }
    .order-xl-last {
      order: 13;
    }
    .order-xl-0 {
      order: 0;
    }
    .order-xl-1 {
      order: 1;
    }
    .order-xl-2 {
      order: 2;
    }
    .order-xl-3 {
      order: 3;
    }
    .order-xl-4 {
      order: 4;
    }
    .order-xl-5 {
      order: 5;
    }
    .order-xl-6 {
      order: 6;
    }
    .order-xl-7 {
      order: 7;
    }
    .order-xl-8 {
      order: 8;
    }
    .order-xl-9 {
      order: 9;
    }
    .order-xl-10 {
      order: 10;
    }
    .order-xl-11 {
      order: 11;
    }
    .order-xl-12 {
      order: 12;
    }
    .offset-xl-0 {
      margin-left: 0;
    }
    .offset-xl-1 {
      margin-left: 8.3333333333%;
    }
    .offset-xl-2 {
      margin-left: 16.6666666667%;
    }
    .offset-xl-3 {
      margin-left: 25%;
    }
    .offset-xl-4 {
      margin-left: 33.3333333333%;
    }
    .offset-xl-5 {
      margin-left: 41.6666666667%;
    }
    .offset-xl-6 {
      margin-left: 50%;
    }
    .offset-xl-7 {
      margin-left: 58.3333333333%;
    }
    .offset-xl-8 {
      margin-left: 66.6666666667%;
    }
    .offset-xl-9 {
      margin-left: 75%;
    }
    .offset-xl-10 {
      margin-left: 83.3333333333%;
    }
    .offset-xl-11 {
      margin-left: 91.6666666667%;
    }
  }
  .form-control {
    display: block;
    width: 100%;
    height: calc(3rem + 0px);
    padding: 0.75rem 0.71875rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #15212f;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px solid #f5f4f2;
    border-radius: 0;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  @media screen and (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none;
    }
  }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0;
  }
  .form-control:focus {
    color: #15212f;
    background-color: #fff;
    border-color: #ff97ad;
    outline: 0;
    box-shadow: 0 0 0 0 rgba(255, 23, 73, 0.25);
  }
  .form-control::placeholder {
    color: #b1b4b7;
    opacity: 1;
  }
  .form-control:disabled,
  .form-control[readonly] {
    background-color: #f5f4f2;
    opacity: 1;
  }

  select.form-control:focus::-ms-value {
    color: #15212f;
    background-color: #fff;
  }

  .form-control-file,
  .form-control-range {
    display: block;
    width: 100%;
  }

  .col-form-label {
    padding-top: calc(0.75rem + 0px);
    padding-bottom: calc(0.75rem + 0px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
  }

  .col-form-label-lg {
    padding-top: calc(0.5rem + 0px);
    padding-bottom: calc(0.5rem + 0px);
    font-size: 1.25rem;
    line-height: 1.5;
  }

  .col-form-label-sm {
    padding-top: calc(0.5rem + 0px);
    padding-bottom: calc(0.5rem + 0px);
    font-size: 0.875rem;
    line-height: 1.5;
  }

  .form-control-plaintext {
    display: block;
    width: 100%;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    margin-bottom: 0;
    line-height: 1.5;
    color: #15212f;
    background-color: transparent;
    border: solid transparent;
    border-width: 0px 0;
  }
  .form-control-plaintext.form-control-sm,
  .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0;
  }

  .form-control-sm {
    height: calc(2.3125rem + 0px);
    padding: 0.5rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
  }

  .form-control-lg {
    height: calc(2.875rem + 0px);
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
  }

  select.form-control[size],
  select.form-control[multiple] {
    height: auto;
  }

  textarea.form-control {
    height: auto;
  }

  .form-group {
    margin-bottom: 1rem;
  }

  .form-text {
    display: block;
    margin-top: 0.25rem;
  }

  .form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
  }
  .form-row > .col,
  .form-row > [class*='col-'] {
    padding-right: 5px;
    padding-left: 5px;
  }

  .form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
  }

  .form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem;
  }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d;
  }

  .form-check-label {
    margin-bottom: 0;
  }

  .form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem;
  }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
  }

  .valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #7ce0d3;
  }

  .valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #15212f;
    background-color: rgba(124, 224, 211, 0.9);
  }

  .was-validated .form-control:valid,
  .form-control.is-valid,
  .was-validated .custom-select:valid,
  .custom-select.is-valid {
    border-color: #7ce0d3;
  }
  .was-validated .form-control:valid:focus,
  .form-control.is-valid:focus,
  .was-validated .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #7ce0d3;
    box-shadow: 0 0 0 0 rgba(124, 224, 211, 0.25);
  }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip,
  .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip,
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block;
  }

  .was-validated .form-control-file:valid ~ .valid-feedback,
  .was-validated .form-control-file:valid ~ .valid-tooltip,
  .form-control-file.is-valid ~ .valid-feedback,
  .form-control-file.is-valid ~ .valid-tooltip {
    display: block;
  }

  .was-validated .form-check-input:valid ~ .form-check-label,
  .form-check-input.is-valid ~ .form-check-label {
    color: #7ce0d3;
  }
  .was-validated .form-check-input:valid ~ .valid-feedback,
  .was-validated .form-check-input:valid ~ .valid-tooltip,
  .form-check-input.is-valid ~ .valid-feedback,
  .form-check-input.is-valid ~ .valid-tooltip {
    display: block;
  }

  .was-validated .custom-control-input:valid ~ .custom-control-label,
  .custom-control-input.is-valid ~ .custom-control-label {
    color: #7ce0d3;
  }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before,
  .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #e3f8f6;
  }
  .was-validated .custom-control-input:valid ~ .valid-feedback,
  .was-validated .custom-control-input:valid ~ .valid-tooltip,
  .custom-control-input.is-valid ~ .valid-feedback,
  .custom-control-input.is-valid ~ .valid-tooltip {
    display: block;
  }
  .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
  .custom-control-input.is-valid:checked ~ .custom-control-label::before {
    background-color: #a5eae1;
  }
  .was-validated .custom-control-input:valid:focus ~ .custom-control-label::before,
  .custom-control-input.is-valid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #f5f4f2, 0 0 0 0 rgba(124, 224, 211, 0.25);
  }

  .was-validated .custom-file-input:valid ~ .custom-file-label,
  .custom-file-input.is-valid ~ .custom-file-label {
    border-color: #7ce0d3;
  }
  .was-validated .custom-file-input:valid ~ .custom-file-label::after,
  .custom-file-input.is-valid ~ .custom-file-label::after {
    border-color: inherit;
  }
  .was-validated .custom-file-input:valid ~ .valid-feedback,
  .was-validated .custom-file-input:valid ~ .valid-tooltip,
  .custom-file-input.is-valid ~ .valid-feedback,
  .custom-file-input.is-valid ~ .valid-tooltip {
    display: block;
  }
  .was-validated .custom-file-input:valid:focus ~ .custom-file-label,
  .custom-file-input.is-valid:focus ~ .custom-file-label {
    box-shadow: 0 0 0 0 rgba(124, 224, 211, 0.25);
  }

  .invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #ff1749;
  }

  .invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(255, 23, 73, 0.9);
  }

  .was-validated .form-control:invalid,
  .form-control.is-invalid,
  .was-validated .custom-select:invalid,
  .custom-select.is-invalid {
    border-color: #ff1749;
  }
  .was-validated .form-control:invalid:focus,
  .form-control.is-invalid:focus,
  .was-validated .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #ff1749;
    box-shadow: 0 0 0 0 rgba(255, 23, 73, 0.25);
  }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip,
  .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip,
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block;
  }

  .was-validated .form-control-file:invalid ~ .invalid-feedback,
  .was-validated .form-control-file:invalid ~ .invalid-tooltip,
  .form-control-file.is-invalid ~ .invalid-feedback,
  .form-control-file.is-invalid ~ .invalid-tooltip {
    display: block;
  }

  .was-validated .form-check-input:invalid ~ .form-check-label,
  .form-check-input.is-invalid ~ .form-check-label {
    color: #ff1749;
  }
  .was-validated .form-check-input:invalid ~ .invalid-feedback,
  .was-validated .form-check-input:invalid ~ .invalid-tooltip,
  .form-check-input.is-invalid ~ .invalid-feedback,
  .form-check-input.is-invalid ~ .invalid-tooltip {
    display: block;
  }

  .was-validated .custom-control-input:invalid ~ .custom-control-label,
  .custom-control-input.is-invalid ~ .custom-control-label {
    color: #ff1749;
  }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before,
  .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #ff97ad;
  }
  .was-validated .custom-control-input:invalid ~ .invalid-feedback,
  .was-validated .custom-control-input:invalid ~ .invalid-tooltip,
  .custom-control-input.is-invalid ~ .invalid-feedback,
  .custom-control-input.is-invalid ~ .invalid-tooltip {
    display: block;
  }
  .was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before,
  .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
    background-color: #ff4a71;
  }
  .was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before,
  .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #f5f4f2, 0 0 0 0 rgba(255, 23, 73, 0.25);
  }

  .was-validated .custom-file-input:invalid ~ .custom-file-label,
  .custom-file-input.is-invalid ~ .custom-file-label {
    border-color: #ff1749;
  }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::after,
  .custom-file-input.is-invalid ~ .custom-file-label::after {
    border-color: inherit;
  }
  .was-validated .custom-file-input:invalid ~ .invalid-feedback,
  .was-validated .custom-file-input:invalid ~ .invalid-tooltip,
  .custom-file-input.is-invalid ~ .invalid-feedback,
  .custom-file-input.is-invalid ~ .invalid-tooltip {
    display: block;
  }
  .was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
  .custom-file-input.is-invalid:focus ~ .custom-file-label {
    box-shadow: 0 0 0 0 rgba(255, 23, 73, 0.25);
  }

  .form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }
  .form-inline .form-check {
    width: 100%;
  }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
    }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0;
    }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle;
    }
    .form-inline .form-control-plaintext {
      display: inline-block;
    }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto;
    }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0;
    }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0;
    }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center;
    }
    .form-inline .custom-control-label {
      margin-bottom: 0;
    }
  }

  .btn-cta {
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    height: calc(2.8125rem + 0px);
    text-decoration: none;
    padding: 0.75rem 1.375rem;
    font-size: 0.875rem;
    line-height: 1.5;
    transition: all 0.15s ease-in-out;
    border: none;
    border-radius: 4px;
  }
  @media screen and (prefers-reduced-motion: reduce) {
    .btn-cta {
      transition: none;
    }
  }
  .btn-cta:hover,
  .btn-cta:focus {
    text-decoration: none;
  }
  .btn-cta.btn-primary {
    color: #fff;
    background-color: #ff1749;
  }
  .btn-cta.btn-primary:hover {
    color: #fff;
    background-color: #ca143b;
  }
  .btn-cta.btn-primary:focus,
  .btn-cta.btn-primary.focus {
    outline: none;
    box-shadow: 0 0 0 0 rgba(255, 23, 73, 0.3);
  }
  .btn-cta.btn-primary:active {
    color: #fff;
    background-color: #ff5177;
  }
  .btn-cta.btn-primary.disabled,
  .btn-cta.btn-primary:disabled {
    color: #d7d2cb;
    font-weight: bold;
    background-color: #f5f4f2;
    cursor: default;
    pointer-events: none;
  }
  .btn-cta.btn-secondary {
    color: #15212f;
    background-color: #d7d2cb;
  }
  .btn-cta.btn-secondary:hover {
    color: #15212f;
    background-color: #c8c1b7;
  }
  .btn-cta.btn-secondary:focus,
  .btn-cta.btn-secondary.focus {
    outline: none;
    box-shadow: 0 0 0 0 rgba(215, 210, 203, 0.3);
  }
  .btn-cta.btn-secondary:active {
    color: #15212f;
    background-color: #e1ded8;
  }
  .btn-cta.btn-secondary.disabled,
  .btn-cta.btn-secondary:disabled {
    color: #d7d2cb;
    font-weight: bold;
    background-color: #f5f4f2;
    cursor: default;
    pointer-events: none;
  }
  .btn-cta.btn-tertiary {
    color: #fff;
    background-color: #15212f;
  }
  .btn-cta.btn-tertiary:hover {
    color: #fff;
    background-color: #05172b;
  }
  .btn-cta.btn-tertiary:focus,
  .btn-cta.btn-tertiary.focus {
    outline: none;
    box-shadow: 0 0 0 0 rgba(21, 33, 47, 0.3);
  }
  .btn-cta.btn-tertiary:active {
    color: #fff;
    background-color: #505963;
  }
  .btn-cta.btn-tertiary.disabled,
  .btn-cta.btn-tertiary:disabled {
    color: #d7d2cb;
    font-weight: bold;
    background-color: #f5f4f2;
    cursor: default;
    pointer-events: none;
  }

  .btn-action {
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    height: calc(2.8125rem + 0px);
    text-decoration: none;
    padding: 0.75rem 1.375rem;
    font-size: 0.875rem;
    line-height: 1.5;
    transition: all 0.15s ease-in-out;
    border: 1px solid;
    border-radius: 25px;
    color: #15212f;
    stroke: #15212f;
    stroke-width: 10px;
    fill: transparent;
    background-color: transparent;
    background-image: none;
    border-color: #15212f;
  }
  @media screen and (prefers-reduced-motion: reduce) {
    .btn-action {
      transition: none;
    }
  }
  .btn-action:hover,
  .btn-action:focus {
    text-decoration: none;
  }
  .btn-action:focus,
  .btn-action.focus {
    outline: none;
    box-shadow: 0 0 0 0 rgba(255, 23, 73, 0.3);
  }
  .btn-action:hover {
    color: #ff1749;
    background-color: transparent;
    border-color: #ff1749;
    stroke: #ff1749;
  }
  .btn-action:not(:disabled):not(.disabled):active,
  .btn-action:not(:disabled):not(.disabled).active,
  .show > .btn-action.dropdown-toggle {
    color: #ff1749;
    background-color: transparent;
    border-color: #ff1749;
    font-weight: bold;
    fill: #ff1749;
    stroke-width: 0;
    stroke: transparent;
  }
  .btn-action.disabled,
  .btn-action:disabled {
    color: #d7d2cb;
    stroke: #d7d2cb;
    border-color: #d7d2cb;
    cursor: default;
    pointer-events: none;
  }
  .btn-action.disabled.active {
    font-weight: bold;
    stroke-width: 0;
    fill: #d7d2cb;
  }
  .btn-action svg {
    pointer-events: none;
    padding: 1px 0;
    margin-right: 0.425rem;
    margin-left: -0.225rem;
    margin-bottom: 0.25rem;
  }

  .btn-icon-only {
    padding: 0;
    width: calc(2.8125rem + 0px);
  }
  .btn-icon-only svg {
    margin: 0 0 1px;
  }

  .btn-facebook {
    color: #fff;
    background-color: #3b60c8;
  }
  .btn-facebook:hover {
    color: #fff;
    background-color: #3b60c8;
  }
  .btn-facebook:focus,
  .btn-facebook.focus {
    outline: none;
    box-shadow: 0 0 0 0 rgba(59, 96, 200, 0.3);
  }
  .btn-facebook:active {
    color: #fff;
    background-color: #2e4ca2;
  }
  .btn-facebook.disabled,
  .btn-facebook:disabled {
    color: #d7dff4;
    font-weight: bold;
    background-color: white;
    cursor: default;
    pointer-events: none;
  }

  .btn-lg {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
  }

  .btn-sm {
    padding: 0.5rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
  }

  .btn-block {
    display: block;
    width: 100%;
  }
  .btn-block + .btn-block {
    margin-top: 0.5rem;
  }

  input[type='submit'].btn-block,
  input[type='reset'].btn-block,
  input[type='button'].btn-block {
    width: 100%;
  }

  .fade {
    transition: opacity 0.15s linear;
  }
  @media screen and (prefers-reduced-motion: reduce) {
    .fade {
      transition: none;
    }
  }
  .fade:not(.show) {
    opacity: 0;
  }

  .collapse:not(.show) {
    display: none;
  }

  .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
  }
  @media screen and (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none;
    }
  }

  .dropup,
  .dropright,
  .dropdown,
  .dropleft {
    position: relative;
  }

  .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
  .dropdown-toggle:empty::after {
    margin-left: 0;
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #15212f;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #fff;
  }

  .dropdown-menu-right {
    right: 0;
    left: auto;
  }

  .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem;
  }
  .dropup .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
  }
  .dropup .dropdown-toggle:empty::after {
    margin-left: 0;
  }

  .dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem;
  }
  .dropright .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
  }
  .dropright .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  .dropright .dropdown-toggle::after {
    vertical-align: 0;
  }

  .dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem;
  }
  .dropleft .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
  }
  .dropleft .dropdown-toggle::after {
    display: none;
  }
  .dropleft .dropdown-toggle::before {
    display: inline-block;
    width: 0;
    height: 0;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent;
  }
  .dropleft .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  .dropleft .dropdown-toggle::before {
    vertical-align: 0;
  }

  .dropdown-menu[x-placement^='top'],
  .dropdown-menu[x-placement^='right'],
  .dropdown-menu[x-placement^='bottom'],
  .dropdown-menu[x-placement^='left'] {
    right: auto;
    bottom: auto;
  }

  .dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #ebe9e5;
  }

  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.75rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #15212f;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }
  .dropdown-item:hover,
  .dropdown-item:focus {
    color: #0d151d;
    text-decoration: none;
    background-color: #f5f4f2;
  }
  .dropdown-item.active,
  .dropdown-item:active {
    color: #15212f;
    text-decoration: none;
    background-color: #f5f4f2;
  }
  .dropdown-item.disabled,
  .dropdown-item:disabled {
    color: #868c93;
    background-color: transparent;
  }

  .dropdown-menu.show {
    display: block;
  }

  .dropdown-item-text {
    display: block;
    padding: 0.75rem 1.5rem;
    color: #15212f;
  }

  .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
  }
  .input-group > .form-control,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
  }
  .input-group > .form-control + .form-control,
  .input-group > .form-control + .custom-select,
  .input-group > .form-control + .custom-file,
  .input-group > .custom-select + .form-control,
  .input-group > .custom-select + .custom-select,
  .input-group > .custom-select + .custom-file,
  .input-group > .custom-file + .form-control,
  .input-group > .custom-file + .custom-select,
  .input-group > .custom-file + .custom-file {
    margin-left: 0px;
  }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3;
  }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4;
  }
  .input-group > .custom-file {
    display: flex;
    align-items: center;
  }
  .input-group-prepend,
  .input-group-append {
    display: flex;
  }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2;
  }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: 0px;
  }

  .input-group-prepend {
    margin-right: 0px;
  }

  .input-group-append {
    margin-left: 0px;
  }

  .input-group-text {
    display: flex;
    align-items: center;
    padding: 0.75rem 0.71875rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #15212f;
    text-align: center;
    white-space: nowrap;
    background-color: #f5f4f2;
    border: 0px solid #f5f4f2;
  }
  .input-group-text input[type='radio'],
  .input-group-text input[type='checkbox'] {
    margin-top: 0;
  }

  .input-group-lg > .form-control,
  .input-group-lg > .input-group-prepend > .input-group-text,
  .input-group-lg > .input-group-append > .input-group-text,
  .input-group-lg > .input-group-prepend > .btn,
  .input-group-lg > .input-group-append > .btn {
    height: calc(2.875rem + 0px);
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
  }

  .input-group-sm > .form-control,
  .input-group-sm > .input-group-prepend > .input-group-text,
  .input-group-sm > .input-group-append > .input-group-text,
  .input-group-sm > .input-group-prepend > .btn,
  .input-group-sm > .input-group-append > .btn {
    height: calc(2.3125rem + 0px);
    padding: 0.5rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
  }

  .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }

  .nav-link {
    display: block;
    padding: 1rem 1rem;
  }
  .nav-link:hover,
  .nav-link:focus {
    text-decoration: none;
  }
  .nav-link.disabled {
    color: #6c757d;
  }

  .nav-tabs {
    border-bottom: 1px solid #dee2e6;
  }
  .nav-tabs .nav-item {
    margin-bottom: -1px;
  }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
  }
  .nav-tabs .nav-link:hover,
  .nav-tabs .nav-link:focus {
    border-color: #e9ecef #e9ecef #dee2e6;
  }
  .nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent;
  }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #f5f4f2;
    border-color: #dee2e6 #dee2e6 #f5f4f2;
  }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #ff1749;
  }

  .nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center;
  }

  .nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }

  .tab-content > .tab-pane {
    display: none;
  }
  .tab-content > .active {
    display: block;
  }

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0, 0, 0, 0.125);
  }
  .card > hr {
    margin-right: 0;
    margin-left: 0;
  }
  .card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
  }

  .card-title {
    margin-bottom: 0.75rem;
  }

  .card-link:hover {
    text-decoration: none;
  }
  .card-link + .card-link {
    margin-left: 1.25rem;
  }

  .card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 0 solid rgba(0, 0, 0, 0.125);
  }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0;
  }

  .card-footer {
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 0 solid rgba(0, 0, 0, 0.125);
  }
  .card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0;
  }

  .card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }

  .card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
  }

  .card-img {
    width: 100%;
  }

  .card-img-top {
    width: 100%;
  }

  .card-img-bottom {
    width: 100%;
  }

  .card-deck {
    display: flex;
    flex-direction: column;
  }
  .card-deck .card {
    margin-bottom: 15px;
  }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px;
    }
    .card-deck .card {
      display: flex;
      flex: 1 0 0%;
      flex-direction: column;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px;
    }
  }

  .card-group {
    display: flex;
    flex-direction: column;
  }
  .card-group > .card {
    margin-bottom: 15px;
  }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap;
    }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0;
    }
    .card-group > .card + .card {
      margin-left: 0;
      border-left: 0;
    }
  }

  .card-columns .card {
    margin-bottom: 0.75rem;
  }
  @media (min-width: 576px) {
    .card-columns {
      column-count: 3;
      column-gap: 1.25rem;
      orphans: 1;
      widows: 1;
    }
    .card-columns .card {
      display: inline-block;
      width: 100%;
    }
  }

  .accordion .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0;
  }
  .accordion .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0;
  }
  .accordion .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .accordion .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0.625rem 0;
    margin-bottom: 0;
    list-style: none;
    background-color: transparent;
  }

  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.625rem;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.625rem;
    color: #868c93;
    content: '/';
  }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline;
  }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none;
  }
  .breadcrumb-item.active {
    color: #15212f;
  }

  .pagination {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-left: 0;
    list-style: none;
  }

  .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #15212f;
    background-color: #fff;
    border: 1px solid #ebe9e5;
  }
  .page-link:hover {
    z-index: 2;
    color: #15212f;
    text-decoration: none;
    background-color: #fff;
    border-color: #e1ddd8;
  }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0 rgba(255, 23, 73, 0.25);
  }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  .page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #ff1749;
    border-color: #ff1749;
  }
  .page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
  }

  .pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5;
  }
  .pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
  }
  .toast {
    width: 350px;
    max-width: 100%;
    font-size: 0.875rem;
    color: #fff;
    pointer-events: auto;
    background-color: #ff1749;
    background-clip: padding-box;
    border: 2px solid #ff1749;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  }
  .toast:not(.showing):not(.show) {
    opacity: 0;
  }
  .toast.hide {
    display: none;
  }

  .toast-container {
    width: max-content;
    max-width: 100%;
    pointer-events: none;
  }
  .toast-container > :not(:last-child) {
    margin-bottom: 15px;
  }

  .toast-header {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.75rem;
    color: #fff;
    background-color: #ff1749;
    background-clip: padding-box;
    border-bottom: 2px solid #ff1749;
  }
  .toast-header .btn-close {
    margin-right: -0.375rem;
    margin-left: 0.75rem;
  }

  .toast-body {
    padding: 0.75rem;
  }

  .toast-container {
    display: block;
    position: fixed;
    z-index: 10000;
  }

  @media (max-width: 767.98px) {
    .toast {
      width: 100%;
    }
    .toast-container {
      min-width: 100%;
      bottom: 0;
    }
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .toast-container {
      left: 5%;
      bottom: 7%;
      max-width: 90%;
    }
  }
  @media (min-width: 992px) {
    .toast-container {
      top: 10%;
      right: 7%;
      max-width: 86%;
    }
  }
  .badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
  }
  .badge:empty {
    display: none;
  }

  .badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
  }

  .badge-primary {
    color: #fff;
    background-color: #ff1749;
  }
  .badge-primary[href]:hover,
  .badge-primary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #e30031;
  }

  .badge-success {
    color: #15212f;
    background-color: #7ce0d3;
  }
  .badge-success[href]:hover,
  .badge-success[href]:focus {
    color: #15212f;
    text-decoration: none;
    background-color: #53d6c5;
  }

  .alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
  }

  .alert-heading {
    color: inherit;
  }

  .alert-link {
    font-weight: 700;
  }

  .alert-dismissible {
    padding-right: 4rem;
  }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit;
  }

  .alert-success {
    color: #15212f;
    background-color: #7ce0d3;
    border-color: #7ce0d3;
  }
  .alert-success hr {
    border-top-color: #67dbcc;
  }
  .alert-success .alert-link {
    color: #05080c;
  }

  .alert-danger {
    color: #fff;
    background-color: #ff1749;
    border-color: #ff1749;
  }
  .alert-danger hr {
    border-top-color: #fd0036;
  }
  .alert-danger .alert-link {
    color: #e6e6e6;
  }

  @keyframes progress-bar-stripes {
    from {
      background-position: 1rem 0;
    }
    to {
      background-position: 0 0;
    }
  }
  .progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #e9ecef;
  }

  .progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #ff1749;
    transition: width 0.6s ease;
  }
  @media screen and (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none;
    }
  }

  .progress-bar-striped {
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-size: 1rem 1rem;
  }

  .progress-bar-animated {
    animation: progress-bar-stripes 1s linear infinite;
  }

  .list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
  }

  .list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit;
  }
  .list-group-item-action:hover,
  .list-group-item-action:focus {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa;
  }
  .list-group-item-action:active {
    color: #15212f;
    background-color: #e9ecef;
  }

  .list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
  }
  .list-group-item:last-child {
    margin-bottom: 0;
  }
  .list-group-item:hover,
  .list-group-item:focus {
    z-index: 1;
    text-decoration: none;
  }
  .list-group-item.disabled,
  .list-group-item:disabled {
    color: #6c757d;
    background-color: #fff;
  }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #ff1749;
    border-color: #ff1749;
  }

  .list-group-flush .list-group-item {
    border-right: 0;
    border-left: 0;
  }
  .list-group-flush:first-child .list-group-item:first-child {
    border-top: 0;
  }
  .list-group-flush:last-child .list-group-item:last-child {
    border-bottom: 0;
  }

  .list-group-item-primary {
    color: #850c26;
    background-color: #ffbecc;
  }
  .list-group-item-primary.list-group-item-action:hover,
  .list-group-item-primary.list-group-item-action:focus {
    color: #850c26;
    background-color: #ffa5b8;
  }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #850c26;
    border-color: #850c26;
  }

  .list-group-item-secondary {
    color: #706d6a;
    background-color: #f4f2f0;
  }
  .list-group-item-secondary.list-group-item-action:hover,
  .list-group-item-secondary.list-group-item-action:focus {
    color: #706d6a;
    background-color: #e9e5e1;
  }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #706d6a;
    border-color: #706d6a;
  }

  .list-group-item-success {
    color: #40746e;
    background-color: #daf6f3;
  }
  .list-group-item-success.list-group-item-action:hover,
  .list-group-item-success.list-group-item-action:focus {
    color: #40746e;
    background-color: #c5f1ec;
  }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #40746e;
    border-color: #40746e;
  }

  .list-group-item-danger {
    color: #850c26;
    background-color: #ffbecc;
  }
  .list-group-item-danger.list-group-item-action:hover,
  .list-group-item-danger.list-group-item-action:focus {
    color: #850c26;
    background-color: #ffa5b8;
  }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #850c26;
    border-color: #850c26;
  }

  .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
  }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  .close:not(:disabled):not(.disabled):hover,
  .close:not(:disabled):not(.disabled):focus {
    color: #000;
    text-decoration: none;
    opacity: 0.75;
  }

  button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
  }

  .modal-open {
    overflow: hidden;
  }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0;
  }

  .modal-dialog {
    position: relative;
    margin: 0;
    width: 100%;
    max-width: none;
    max-width: initial;
    pointer-events: none;

    @media only screen and ${breakpoints.device.l} {
      max-width: 500px;
      margin: 1.75rem auto;
    }
  }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%);
  }
  @media screen and (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
      transition: none;
    }
  }
  .modal.show .modal-dialog {
    transform: translate(0, 0);
  }

  .modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 0.5rem * 2);
  }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 0.5rem * 2);
    content: '';
  }

  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #ebe9e5;
    background-clip: padding-box;
    border: 1px solid #ebe9e5;
    outline: 0;
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000;
  }
  .modal-backdrop.fade {
    opacity: 0;
  }
  .modal-backdrop.show {
    opacity: 0.5;
  }

  .modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #ebe9e5;
  }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
  }

  .modal-title {
    margin-bottom: 0;
    line-height: 1.5;
  }

  .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
  }

  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #ebe9e5;
  }
  .modal-footer > :not(:first-child) {
    margin-left: 0.25rem;
  }
  .modal-footer > :not(:last-child) {
    margin-right: 0.25rem;
  }

  .modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
  }

  @media (min-width: 576px) {
    .modal-dialog-centered {
      min-height: calc(100% - 1.75rem * 2);
    }
    .modal-dialog-centered::before {
      height: calc(100vh - 1.75rem * 2);
    }
    .modal-sm {
      max-width: 300px;
    }
  }
  @media (min-width: 992px) {
    .modal-lg {
      max-width: 800px;
    }
  }
  .carousel {
    position: relative;
  }

  .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  .carousel-item {
    position: relative;
    display: none;
    align-items: center;
    width: 100%;
    backface-visibility: hidden;
    perspective: 1000px;
  }

  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    display: block;
    transition: transform 0.6s ease;
  }
  @media screen and (prefers-reduced-motion: reduce) {
    .carousel-item.active,
    .carousel-item-next,
    .carousel-item-prev {
      transition: none;
    }
  }

  .carousel-item-next,
  .carousel-item-prev {
    position: absolute;
    top: 0;
  }

  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    transform: translateX(0);
  }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      transform: translate3d(0, 0, 0);
    }
  }

  .carousel-item-next,
  .active.carousel-item-right {
    transform: translateX(100%);
  }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next,
    .active.carousel-item-right {
      transform: translate3d(100%, 0, 0);
    }
  }

  .carousel-item-prev,
  .active.carousel-item-left {
    transform: translateX(-100%);
  }
  @supports (transform-style: preserve-3d) {
    .carousel-item-prev,
    .active.carousel-item-left {
      transform: translate3d(-100%, 0, 0);
    }
  }

  .carousel-fade .carousel-item {
    opacity: 0;
    transition-duration: 0.6s;
    transition-property: opacity;
  }
  .carousel-fade .carousel-item.active,
  .carousel-fade .carousel-item-next.carousel-item-left,
  .carousel-fade .carousel-item-prev.carousel-item-right {
    opacity: 1;
  }
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    opacity: 0;
  }
  .carousel-fade .carousel-item-next,
  .carousel-fade .carousel-item-prev,
  .carousel-fade .carousel-item.active,
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-prev {
    transform: translateX(0);
  }
  @supports (transform-style: preserve-3d) {
    .carousel-fade .carousel-item-next,
    .carousel-fade .carousel-item-prev,
    .carousel-fade .carousel-item.active,
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-prev {
      transform: translate3d(0, 0, 0);
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    color: #15212f;
    text-align: center;
    opacity: 1;
  }
  .carousel-control-prev:hover,
  .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #15212f;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
  }

  .carousel-control-prev {
    left: 0;
  }

  .carousel-control-next {
    right: 0;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    display: inline-block;
    width: 45px;
    height: 45px;
    background: transparent no-repeat center center;
    background-size: 100% 100%;
  }

  .carousel-control-prev-icon {
    background-image: none;
  }

  .carousel-control-next-icon {
    background-image: none;
  }

  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 10px;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
  }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 12px;
    height: 12px;
    margin-right: 10px;
    margin-left: 10px;
    text-indent: -999px;
    cursor: pointer;
    background-color: rgba(215, 210, 203, 0.5);
  }
  .carousel-indicators li::before {
    position: absolute;
    top: -10px;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 10px;
    content: '';
  }
  .carousel-indicators li::after {
    position: absolute;
    bottom: -10px;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 10px;
    content: '';
  }
  .carousel-indicators .active {
    background-color: #d7d2cb;
  }

  .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
  }

  @keyframes spinner-border {
    to {
      transform: rotate(360deg);
    }
  }
  .spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border 0.75s linear infinite;
  }

  .spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
  }

  @keyframes spinner-grow {
    0% {
      transform: scale(0);
    }
    50% {
      opacity: 1;
    }
  }
  .spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    animation: spinner-grow 0.75s linear infinite;
  }

  .spinner-grow-sm {
    width: 1rem;
    height: 1rem;
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }

  .sr-only-focusable:active,
  .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  @media print {
    *,
    *::before,
    *::after {
      text-shadow: none !important;
      box-shadow: none !important;
    }
    a {
      text-decoration: underline;
    }
    abbr[title]::after {
      content: ' (' attr(title) ')';
    }
    pre {
      white-space: pre-wrap !important;
    }
    pre,
    blockquote {
      border: 1px solid #adb5bd;
      page-break-inside: avoid;
    }
    thead {
      display: table-header-group;
    }
    tr,
    img {
      page-break-inside: avoid;
    }
    p,
    h2,
    h3 {
      orphans: 3;
      widows: 3;
    }
    h2,
    h3 {
      page-break-after: avoid;
    }
    @page {
      size: a3;
    }
    body {
      min-width: 992px !important;
    }
    .container {
      min-width: 992px !important;
    }
    .navbar {
      display: none;
    }
    .badge {
      border: 1px solid #000;
    }
    .table {
      border-collapse: collapse !important;
    }
    .table td,
    .table th {
      background-color: #fff !important;
    }
    .table-bordered th,
    .table-bordered td {
      border: 1px solid #dee2e6 !important;
    }
    .table-dark {
      color: inherit;
    }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6;
    }
    .table .thead-dark th {
      color: inherit;
      border-color: #dee2e6;
    }
  }
`;

export const StyledBootstrapScopeWrapper = styled.div<{shouldLoadStyles: boolean}>`
  ${(props) => (props.shouldLoadStyles ? bootstrapStyles : '')}
`;
