import {
  STATUS_IDLE,
  STATUS_LOADING,
  STATUS_SUCCEEDED,
  STATUS_FAILED,
  LOAD_SEARCH_PREVIEW_REQUEST,
  LOAD_SEARCH_PREVIEW_SUCCESS,
  LOAD_SEARCH_PREVIEW_FAILURE,
  LOAD_GALLERY_SEARCH_REQUEST,
  LOAD_GALLERY_SEARCH_SUCCESS,
  LOAD_GALLERY_SEARCH_FAILURE,
  CLEAR_GALLERY_SEARCH_RESULTS,
  SEED_GALLERY_SEARCH_DATA_REQUEST,
  SEED_GALLERY_SEARCH_DATA_SUCCESS,
  SEED_GALLERY_SEARCH_DATA_FAILURE,
  PROMOTE_PRODUCT_SUCCESS,
} from '../actionTypes';

const initialState = {
  status: {
    seedData: STATUS_IDLE,
    searchPreview: STATUS_IDLE,
    search: STATUS_IDLE,
  },
  data: {
    timestamp: 0,
    results: {},
    totalCount: null,
    totalPageCount: 0,
    previewCount: 0,
  },
  error: null,
};

const galleryReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SEARCH_PREVIEW_REQUEST:
      return {...state, status: {...state.status, searchPreview: STATUS_LOADING}};
    case LOAD_SEARCH_PREVIEW_SUCCESS:
      return {
        ...state,
        status: {...state.status, searchPreview: STATUS_SUCCEEDED},
        data: {...state.data, previewCount: action.response.data.count},
        error: null,
      };
    case LOAD_SEARCH_PREVIEW_FAILURE:
      return {
        ...state,
        status: {...state.status, searchPreview: STATUS_FAILED},
        error: action.error,
      };
    case LOAD_GALLERY_SEARCH_REQUEST:
      return {...state, status: {...state.status, search: STATUS_LOADING}};
    case LOAD_GALLERY_SEARCH_SUCCESS:
      const pageData = {...state.data.results};
      pageData[action.page] = [...action.response.data.results];
      return {
        ...state,
        status: {...state.status, search: STATUS_SUCCEEDED},
        data: {
          ...state.data,
          results: pageData,
          totalCount: action.response.data.count,
          totalPageCount: action.response.data.num_pages,
          previewCount: action.response.data.count,
        },
      };
    case LOAD_GALLERY_SEARCH_FAILURE:
      return {...state, status: {...state.status, search: STATUS_FAILED}, error: action.error};
    case CLEAR_GALLERY_SEARCH_RESULTS:
      return {...initialState, status: {...initialState.status, seedData: state.status.seedData}};
    case SEED_GALLERY_SEARCH_DATA_REQUEST:
      return {...state, status: {...state.status, seedData: STATUS_LOADING}};
    case SEED_GALLERY_SEARCH_DATA_SUCCESS:
      const pageInitData = {...state.data.results};
      pageInitData[action.page] = [...action.response.data.results];
      return {
        ...state,
        status: {...state.status, seedData: STATUS_SUCCEEDED},
        data: {
          ...state.data,
          results: pageInitData,
          totalCount: action.response.data.count,
          totalPageCount: action.response.data.num_pages,
          previewCount: action.response.data.count,
        },
      };
    case SEED_GALLERY_SEARCH_DATA_FAILURE:
      return {...state, status: {...state.status, seedData: STATUS_FAILED}, error: action.error};
    case PROMOTE_PRODUCT_SUCCESS:
      const galleryResults = {...state.data.results};

      for (const page in galleryResults) {
        if (galleryResults.hasOwnProperty(page)) {
          const pageResults = galleryResults[page];
          for (let i = 0; i < pageResults.length; i++) {
            if (pageResults[i].slug === action.slug) {
              pageResults[i].cb = 'a';
              return {...state, data: {...state.data, results: {...galleryResults}}};
            }
          }
        }
      }

      return {...state};
    default:
      return {...state};
  }
};

export default galleryReducer;
export const selectGalleryData = (state) => state.gallery.data;
export const selectGallerySeedStatus = (state) => state.gallery.status.seedData;
