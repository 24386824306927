import {
  GALLERY_PAGINATION_USED_BY_USER,
  GALLERY_FILTER_USED_BY_USER,
  GALLERY_USED_BY_USER,
  GALLERY_SORT_USED_BY_USER,
  GALLERY_URL_CHANGED,
} from '../actionTypes';

const initialState = {
  gallery: {
    url_changes: 0,
    filters_used: 0,
    pagination_used: 0,
    sort_used: 0,
  },
};

const trackingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GALLERY_FILTER_USED_BY_USER:
      return {
        ...state,
        gallery: {
          ...state.gallery,
          filters_used: state.gallery.filters_used + 1,
        },
      };
    case GALLERY_PAGINATION_USED_BY_USER:
      return {
        ...state,
        gallery: {
          ...state.gallery,
          pagination_used: state.gallery.pagination_used + 1,
        },
      };
    case GALLERY_SORT_USED_BY_USER:
      return {
        ...state,
        gallery: {
          ...state.gallery,
          sort_used: state.gallery.sort_used + 1,
        },
      };
    case GALLERY_URL_CHANGED:
      return {
        ...state,
        gallery: {
          ...state.gallery,
          url_changes: state.gallery.url_changes + 1,
        },
      };
    default:
      return {...state};
  }
};

export default trackingReducer;
export const selectGalleryTracking = (state) => state.tracking.gallery;
