import {
  STATUS_FAILED,
  STATUS_SUCCEEDED,
  STATUS_LOADING,
  STATUS_IDLE,
  STATUS_UPDATING,
  LOAD_PRODUCT_FAVOURITES_REQUEST,
  LOAD_PRODUCT_FAVOURITES_SUCCESS,
  LOAD_PRODUCT_FAVOURITES_FAILURE,
  SAVE_PRODUCT_FAVOURITE_REQUEST,
  SAVE_PRODUCT_FAVOURITE_SUCCESS,
  SAVE_PRODUCT_FAVOURITE_FAILURE,
} from '../actionTypes';

const productFavouritesInitialState = {
  status: STATUS_IDLE,
  error: null,
  data: [],
};

const productFavouritesReducer = (state = productFavouritesInitialState, action) => {
  switch (action.type) {
    case LOAD_PRODUCT_FAVOURITES_REQUEST:
      return {...state, status: STATUS_LOADING};
    case LOAD_PRODUCT_FAVOURITES_SUCCESS:
      return {...state, status: STATUS_SUCCEEDED, data: [...action.response.data]};
    case LOAD_PRODUCT_FAVOURITES_FAILURE:
      return {...state, status: STATUS_FAILED, error: action.error};
    case SAVE_PRODUCT_FAVOURITE_REQUEST:
      return {...state, status: STATUS_UPDATING};
    case SAVE_PRODUCT_FAVOURITE_SUCCESS:
      const favourites = [...state.data];
      const productInState = favourites.find((element) => element.id === action.product_id);
      if (!productInState) {
        // Add favourite to list so we don't have to fetch all of them again
        const product = {
          id: action.product_id,
          slug: action.product_slug,
          artist_id: action.artist_id,
          artist_slug: action.artist_slug,
        };
        favourites.push(product);
        return {...state, status: STATUS_SUCCEEDED, data: favourites};
      } else {
        // Remove favourite from list so we don't have to fetch all of them again
        const reducedFavourites = favourites.filter(function (obj) {
          return obj.id !== action.product_id;
        });
        return {...state, status: STATUS_SUCCEEDED, data: reducedFavourites};
      }

    case SAVE_PRODUCT_FAVOURITE_FAILURE:
      return {...state, status: STATUS_FAILED, error: action.error};
    default:
      return {...state};
  }
};

export default productFavouritesReducer;

// Export a reusable selector so state structure is abstracted for components
export const selectProductFavouritesData = (state) => state.productFavourites.data;
