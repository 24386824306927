import {
  STATUS_IDLE,
  STATUS_LOADING,
  STATUS_SUCCEEDED,
  STATUS_FAILED,
  LOAD_CURRENCY_RATES_REQUEST,
  LOAD_CURRENCY_RATES_SUCCESS,
  LOAD_CURRENCY_RATES_FAILURE
} from '../actionTypes';

// TODO: default data from 2021-08-09
const defaultData = {"EUR": {"EUR": 1.0, "USD": 1.1807, "GBP": 0.8484, "CAD": 1.4763, "AUD": 1.5968}, "USD": {"EUR": 0.846955, "USD": 1.0, "GBP": 0.718557, "CAD": 1.25036, "AUD": 1.352418}, "GBP": {"EUR": 1.178689, "USD": 1.391678, "GBP": 1.0, "CAD": 1.740099, "AUD": 1.882131}, "CAD": {"EUR": 0.677369, "USD": 0.79977, "GBP": 0.57468, "CAD": 1.0, "AUD": 1.081623}, "AUD": {"EUR": 0.626253, "USD": 0.739416, "GBP": 0.531313, "CAD": 0.924537, "AUD": 1.0}};

const initialState = {
  status: STATUS_IDLE,
  data: defaultData,
  error: null
};

const currencyRatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CURRENCY_RATES_REQUEST:
      return {...state, status: STATUS_LOADING};
    case LOAD_CURRENCY_RATES_SUCCESS:
      return {...state, status: STATUS_SUCCEEDED, data: {...state.data, ...action.response.data}};
    case LOAD_CURRENCY_RATES_FAILURE:
      return {...state, status: STATUS_FAILED, error: action.error};
    default:
      return {...state};
  }
};

export default currencyRatesReducer;

// Export a reusable selector so state structure is abstracted for components
export const selectCurrencyRatesData = state => state.currencyRates.data;
