import axios from 'axios';
import React from 'react';

const elementFactory = (document, window) => {
  // Traversing
  const find = (selector, el = document) => el.querySelector(selector);
  const findAll = (selector, el = document) => {
    const divs = el.querySelectorAll(selector);
    return [...divs];
  };
  const parent = (el) => el.parentNode;

  // Events
  const on = (ev, fn, el, options) => el.addEventListener(ev, fn, options);
  const off = (ev, fn, el, options) => el.removeEventListener(ev, fn, options);
  const trigger = (event, el) => el[event]();

  // Manipulation/css
  const innerHeight = (el) => el.clientHeight; // including padding
  const outerHeight = (el) => el.offsetHeight; // including border
  const innerWidth = (el) => el?.clientWidth; // including padding
  const outerWidth = (el) => el.offsetWidth; // including border
  const scrollHeight = (el) => el.scrollHeight;
  const scrollWidth = (el) => el.scrollWidth;
  const offsetTop = (el) => el.offsetTop;
  const offsetLeft = (el) => el.offsetLeft;
  // The vertical scroll position is the same as
  // the number of pixels that are hidden from view above the scrollable area
  const scrollTop = (el) => (el === window ? el.scrollY : el.scrollTop);
  const scrollLeft = (el) => (el === window ? el.scrollX : el.scrollLeft);
  const hasVerticalScrollbar = (el) => scrollHeight(el) > outerHeight(el);
  const hasHorizontalScrollbar = (el) => scrollWidth(el) > outerWidth(el);
  const reflow = (el) => el.offsetHeight;

  const hasClass = (x, el) => el.classList.contains(x);
  const addClass = (x, el) => {
    el.classList.add(x);
    return el;
  };
  const removeClass = (x, el) => {
    el.classList.remove(x);
    return el;
  };
  const toggleClass = (x, el) => {
    return hasClass(x, el) ? removeClass(x, el) : addClass(x, el);
  };
  const getAttribute = (x, el) => el.getAttribute(x);
  const setAttribute = (x, val, el) => el.setAttribute(x, val);
  const removeAttribute = (x, el) => el.removeAttribute(x);

  const getScrollbarWidth = () => {
    const el = document.createElement('div');
    el.style.position = 'absolute';
    el.style.top = '-9999px';
    el.style.width = '50px';
    el.style.height = '50px';
    el.style.overflow = 'scroll';
    document.body.appendChild(el);
    const scrollbarWidth = el.getBoundingClientRect().width - el.clientWidth;
    document.body.removeChild(el);
    return scrollbarWidth;
  };

  const isDocHidden = () => document.hidden;

  const isVisible = (el) => !!(el.offsetWidth || el.offsetHeight || el.getClientRects().length);

  const addClasses = (xs, el) => {
    xs.forEach((x) => {
      addClass(x, el);
    });
    return el;
  };

  const removeClasses = (xs, el) => {
    xs.forEach((x) => {
      removeClass(x, el);
    });
    return el;
  };

  const getCssValue = (x, el) => getComputedStyle(el)[x];

  const loadScript = (id, src, onLoad = () => {}) => {
    if (document.getElementById(id)) {
      onLoad();
      return;
    }
    const js = document.createElement('script');
    js.id = id;
    js.defer = true;
    js.src = src;
    js.onload = onLoad;
    document.head.appendChild(js);
  };

  const getImageData = (image, _document) => {
    const img = new Image();

    const promise = new Promise((resolve, reject) => {
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;

        resolve({width, height});
      };

      img.onerror = reject;
    });

    img.src = image;

    return promise;
  };

  const getConvertedPrices = (conversionRates, product) => {
    let prices = {};
    const basePrice = product.display_price;

    prices[product.currency] = Math.round(basePrice / 100);
    if (conversionRates && 'GBP' in conversionRates) {
      const actualCurrencyConversionRates = conversionRates[product.currency];
      prices = {
        GBP: Math.round((basePrice * actualCurrencyConversionRates['GBP']) / 100),
        USD: Math.round((basePrice * actualCurrencyConversionRates['USD']) / 100),
        EUR: Math.round((basePrice * actualCurrencyConversionRates['EUR']) / 100),
        CAD: Math.round((basePrice * actualCurrencyConversionRates['CAD']) / 100),
        AUD: Math.round((basePrice * actualCurrencyConversionRates['AUD']) / 100),
      };
    }

    return prices;
  };

  const recommendedDataFormat = async (conversionRates, data, _document) => {
    const imageData = await getImageData(data.photo, _document);
    const prices = getConvertedPrices(conversionRates, data);

    const productData = {
      dataSet: 'attraqt',
      id: parseInt(data._id.original_id),
      slug: data.title.toLowerCase().replace(' ', '-'),
      name: data.title,
      url: data.url,
      artist_slug: data.artist_name.toLowerCase().replace(' ', ''),
      artist_name: data.artist_name,
      artist_url: `/${data.artist_name.toLowerCase().replace(' ', '')}`,
      prices: prices,
      original_prices: {
        GBP: 0,
        USD: 0,
        EUR: 0,
        CAD: 0,
        AUD: 0,
      },
      has_original_price: false,
      currency: data.currency,
      dimensions: {
        cm: data.dimension_description,
        in: null,
      },
      units: 'cm',
      quantity: parseInt(data.is_in_stock),
      category_full_name: data.sub_category,
      is_new: data.is_new === 1,
      is_in_stock: data.is_in_stock === 1,
      average_colour: '7a7a7a',
      main_image_set: [
        {
          url: data.photo,
          width: imageData.width,
          height: imageData.height,
          retina_url: data.photo,
          retina_width: imageData.width * 2,
          retina_height: imageData.height * 2,
        },
      ],
      main_image: {
        url: data.photo,
        width: imageData.width,
        height: imageData.height,
        retina_url: data.photo,
        retina_width: imageData.width * 2,
        retina_height: imageData.height * 2,
      },
    };

    return productData;
  };

  return {
    find,
    findAll,
    parent,

    on,
    off,
    trigger,

    innerHeight,
    outerHeight,
    innerWidth,
    outerWidth,
    scrollHeight,
    scrollWidth,
    offsetTop,
    offsetLeft,
    scrollTop,
    scrollLeft,
    hasVerticalScrollbar,
    hasHorizontalScrollbar,
    reflow,

    hasClass,
    addClass,
    removeClass,
    toggleClass,
    getAttribute,
    setAttribute,
    removeAttribute,

    getScrollbarWidth,
    isDocHidden,
    isVisible,
    addClasses,
    removeClasses,
    getCssValue,
    loadScript,

    recommendedDataFormat,
  };
};

export default elementFactory;
