const toastFactory = (document, window, $) => {
  const _defaults = {
    duration: 4000,
    onComplete: () => {},
    transitionIn:
      'transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1), opacity 300ms cubic-bezier(0.215, 0.61, 0.355, 1)',
    transitionOut:
      'transform 375ms cubic-bezier(0.19, 1, 0.22, 1), opacity 375ms cubic-bezier(0.19, 1, 0.22, 1)',
    transitionInMs: 300,
    transitionOutMs: 375,
    isHtml: false,
  };

  const div = () => document.createElement('div');
  const textNode = (x) => document.createTextNode(x);
  const tag = (x) => document.createElement(x);

  const getContainer = () => {
    const x = $.find('.toast-container');
    if (x) {
      return x;
    }
    const y = div();
    y.className = 'toast-container';
    $.setAttribute('aria-live', 'polite', y);
    $.setAttribute('aria-atomic', 'true', y);
    document.body.appendChild(y);
    return y;
  };

  const makeCloseBtn = () => {
    const btn = tag('button');
    btn.className = 'close';
    btn.style.marginLeft = '8px';

    $.setAttribute('type', 'button', btn);
    $.setAttribute('data-dismiss', 'toast', btn);
    $.setAttribute('aria-label', 'Close', btn);

    const span = tag('span');
    $.setAttribute('aria-hidden', 'true', span);
    span.innerHTML = '&times;';

    btn.appendChild(span);

    return btn;
  };

  const makeToast = () => {
    const el = div();
    el.className = 'toast';
    $.setAttribute('role', 'alert', el);
    $.setAttribute('aria-live', 'assertive', el);
    $.setAttribute('aria-atomic', 'true', el);

    return el;
  };

  const makeHeader = (text) => {
    const header = div();
    header.className = 'toast-header';

    const strong = tag('strong');
    strong.style.marginRight = 'auto';
    strong.appendChild(textNode(text));

    const small = tag('small');
    small.className = 'text-muted';
    small.appendChild(textNode('just now'));

    header.appendChild(strong);
    header.appendChild(small);

    return header;
  };

  const makeBody = (text) => {
    const el = div();
    el.className = 'toast-body';
    el.appendChild(textNode(text));

    return el;
  };

  const create = (headerText, bodyText, isHtml) => {
    if (isHtml) {
      const toast = makeToast();

      if (bodyText instanceof HTMLElement) {
        toast.appendChild(bodyText);
      } else {
        toast.innerHTML = bodyText;
      }

      return [toast, $.find('[data-dismiss=toast]', toast)];
    }

    const toast = makeToast();
    const header = makeHeader(headerText);
    const closeBtn = makeCloseBtn();

    header.appendChild(closeBtn);
    toast.appendChild(header);
    toast.appendChild(makeBody(bodyText));

    return [toast, closeBtn];
  };

  return (headerText, bodyText, options) => {
    const _opts = Object.assign({}, _defaults, options);
    const [toast, closeBtn] = create(headerText, bodyText, _opts.isHtml);
    const container = getContainer();

    const dismiss = (cb = _opts.onComplete) => {
      toast.style.transition = _opts.transitionOut;
      toast.style.transform = 'translate(0, -40px)';
      toast.style.opacity = 0;

      setTimeout(() => {
        bindEvents($.off);
        container.removeChild(toast);
        cb();
      }, _opts.transitionOutMs);
    };

    const handleClose = (e) => {
      e.preventDefault();
      dismiss();
    };

    const handleMouseenter = () => {
      $.addClass('panning', toast);
    };

    const handleMouseleave = () => {
      $.removeClass('panning', toast);
    };

    const bindEvents = (fn) => {
      if (closeBtn) {
        fn('click', handleClose, closeBtn);
      }
      fn('mouseenter', handleMouseenter, toast);
      fn('mouseleave', handleMouseleave, toast);
    };

    // init
    bindEvents($.on);
    toast.style.transform = 'translate(0, 35px)';
    toast.style.opacity = 0;
    toast.style.transition = _opts.transitionIn;
    container.appendChild(toast);
    $.reflow(toast);
    toast.style.transform = 'translate(0, 0)';
    toast.style.opacity = 1;

    if (_opts.duration === Infinity) {
      return {
        toast,
        dismiss,
      };
    }

    // Allows timer to be pause while being panned
    let timeLeft = _opts.duration;
    const counterInterval = setInterval(function() {
      if (toast.parentNode === null) {
        window.clearInterval(counterInterval);
      }

      // If toast is not being dragged, decrease its time remaining
      if (!$.hasClass('panning', toast)) {
        timeLeft -= 20;
      }

      if (timeLeft <= 0) {
        window.clearInterval(counterInterval);
        dismiss();
      }
    }, 20);
  };
};

export default toastFactory;
